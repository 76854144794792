/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Date (isoformat) */
  Date: { input: any; output: any }
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any }
  /** Decimal (fixed-point) */
  Decimal: { input: any; output: any }
  /** Email scalar type */
  Email: { input: any; output: any }
  HTML: { input: any; output: any }
  /** Always returns True */
  OK: { input: any; output: any }
  /** Phone number scalar type */
  PhoneNumber: { input: any; output: any }
}

export type AcceptTermsInput = {
  userEmail: Scalars['String']['input']
  version: Scalars['Int']['input']
}

export type AdminBookingApprovalPayload = BookingType | OperationInfo

export type ApproveBookingInput = {
  approved: Scalars['Boolean']['input']
  id: Scalars['Int']['input']
}

export type ApproveDateInput = {
  approved: Scalars['Boolean']['input']
  id: Scalars['Int']['input']
}

export enum BillingStatusChoices {
  Checked = 'CHECKED',
  Invoiced = 'INVOICED',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export type BinaryAnswerInput = {
  answer: Scalars['Boolean']['input']
  questionId: Scalars['ID']['input']
}

/** BinaryQuestion(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image, question_ptr, need_support) */
export type BinaryQuestionType = {
  __typename?: 'BinaryQuestionType'
  id: Scalars['ID']['output']
  questionPtr: DjangoModelType
}

export type BlogListResponse = {
  __typename?: 'BlogListResponse'
  items: Array<BlogType>
  totalItemsCount: Scalars['Int']['output']
}

/** Blog(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr) */
export type BlogType = {
  __typename?: 'BlogType'
  author?: Maybe<Scalars['String']['output']>
  body: Scalars['HTML']['output']
  child?: Maybe<BlogTypeGuideType>
  date: Scalars['Date']['output']
  id: Scalars['ID']['output']
  images?: Maybe<Array<Image>>
  intro: Scalars['String']['output']
  mainImage?: Maybe<Image>
  relatedBlogs: Array<BlogType>
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  themes: Array<ThemeType>
  title: Scalars['String']['output']
  vendor?: Maybe<VendorType>
}

/** Blog(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr) */
export type BlogTypeImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Blog(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr) */
export type BlogTypeMainImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type BlogTypeGuideType = BlogType | GuideType

/** BookingAction(id, sort_order, booking, type, created_at, user, approved, date) */
export type BookingActionType = {
  __typename?: 'BookingActionType'
  approved?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['DateTime']['output']
  date?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
  type: BookingsBookingActionTypeEnum
  user?: Maybe<DjangoModelType>
}

export type BookingDateApprovalPayload = BookingType | OperationInfo

/** Booking(id, created_at, updated_at, price, status, employee, product, date, employee_comment, vendor_billing_status, company_billing_status) */
export type BookingFilter = {
  AND?: InputMaybe<BookingFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<BookingFilter>
  OR?: InputMaybe<BookingFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** For services: current proposal for when it should take place */
  date?: InputMaybe<Scalars['Date']['input']>
  employeeComment?: InputMaybe<Scalars['String']['input']>
  product?: InputMaybe<DjangoModelFilterInput>
  status?: InputMaybe<BookingsBookingStatusEnum>
}

export type BookingListResponse = {
  __typename?: 'BookingListResponse'
  items: Array<BookingType>
  totalItemsCount: Scalars['Int']['output']
}

/** Booking(id, created_at, updated_at, price, status, employee, product, date, employee_comment, vendor_billing_status, company_billing_status) */
export type BookingType = {
  __typename?: 'BookingType'
  actions: Array<BookingActionType>
  companyBillingStatus: BookingsBookingCompanyBillingStatusEnum
  createdAt: Scalars['DateTime']['output']
  /** For services: current proposal for when it should take place */
  date?: Maybe<Scalars['Date']['output']>
  employee: Employee
  employeeComment: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** price of the product when the booking is created */
  price: Scalars['Decimal']['output']
  product: ProductType
  status: BookingsBookingStatusEnum
  vendorBillingStatus: BookingsBookingVendorBillingStatusEnum
}

/** booking action | type */
export enum BookingsBookingActionTypeEnum {
  /** Employee Create */
  EmployeeCreate = 'EMPLOYEE_CREATE',
  /** Employee Date Approval */
  EmployeeDateApproval = 'EMPLOYEE_DATE_APPROVAL',
  /** Thrive Approval */
  ThriveApproval = 'THRIVE_APPROVAL',
  /** Vendor Approval */
  VendorApproval = 'VENDOR_APPROVAL',
  /** Vendor Date Suggestion */
  VendorDateSuggestion = 'VENDOR_DATE_SUGGESTION',
}

/** booking | company billing status */
export enum BookingsBookingCompanyBillingStatusEnum {
  /** Checked */
  Checked = 'CHECKED',
  /** Invoiced */
  Invoiced = 'INVOICED',
  /** Paid */
  Paid = 'PAID',
  /** Pending */
  Pending = 'PENDING',
}

/** booking | status */
export enum BookingsBookingStatusEnum {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Canceled */
  Canceled = 'CANCELED',
  /** Needs Employee Action */
  NeedsEmployeeAction = 'NEEDS_EMPLOYEE_ACTION',
  /** Needs Thrive Action */
  NeedsThriveAction = 'NEEDS_THRIVE_ACTION',
  /** Needs Vendor Action */
  NeedsVendorAction = 'NEEDS_VENDOR_ACTION',
}

/** booking | vendor billing status */
export enum BookingsBookingVendorBillingStatusEnum {
  /** Checked */
  Checked = 'CHECKED',
  /** Invoiced */
  Invoiced = 'INVOICED',
  /** Paid */
  Paid = 'PAID',
  /** Pending */
  Pending = 'PENDING',
}

export type BoolBaseFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Boolean']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Boolean']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
}

/** BudgetRequest(id, created_at, updated_at, employee, approved_by_staff, approved_by_company, message, reject_reason, amount, product) */
export type BudgetCompanyApprovalInput = {
  approvedByCompany?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  rejectReason?: InputMaybe<Scalars['String']['input']>
}

/** BudgetRequest(id, created_at, updated_at, employee, approved_by_staff, approved_by_company, message, reject_reason, amount, product) */
export type BudgetRequest = {
  __typename?: 'BudgetRequest'
  amount?: Maybe<Scalars['Decimal']['output']>
  approvedByCompany?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['DateTime']['output']
  employee: Employee
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  product?: Maybe<ProductType>
  updatedAt: Scalars['DateTime']['output']
}

/** BudgetRequest(id, created_at, updated_at, employee, approved_by_staff, approved_by_company, message, reject_reason, amount, product) */
export type BudgetRequestInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  productId?: InputMaybe<Scalars['ID']['input']>
}

export type BudgetRequestListResponse = {
  __typename?: 'BudgetRequestListResponse'
  items: Array<BudgetRequest>
  outstandingItemsCount: Scalars['Int']['output']
  totalItemsCount: Scalars['Int']['output']
}

export type BulkUploadError = {
  __typename?: 'BulkUploadError'
  id: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
}

/** Campaign(id, created_at, updated_at, name, segment, message, sent_at, scheduled_at) */
export type CampaignInput = {
  message: Scalars['String']['input']
  name: Scalars['String']['input']
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>
  segment: Scalars['ID']['input']
}

export type CampaignListResponse = {
  __typename?: 'CampaignListResponse'
  items: Array<CampaignType>
  totalItemsCount: Scalars['Int']['output']
}

/** CampaignMessageTemplate(id, created_at, updated_at, name, content) */
export type CampaignMessageTemplateInput = {
  content: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type CampaignMessageTemplateListResponse = {
  __typename?: 'CampaignMessageTemplateListResponse'
  items: Array<CampaignMessageTemplateType>
  totalItemsCount: Scalars['Int']['output']
}

/** CampaignMessageTemplate(id, created_at, updated_at, name, content) */
export type CampaignMessageTemplateType = {
  __typename?: 'CampaignMessageTemplateType'
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** Campaign(id, created_at, updated_at, name, segment, message, sent_at, scheduled_at) */
export type CampaignType = {
  __typename?: 'CampaignType'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  name: Scalars['String']['output']
  scheduledAt?: Maybe<Scalars['DateTime']['output']>
  segment: SegmentType
  sentAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type ChapterType = {
  __typename?: 'ChapterType'
  body: Scalars['HTML']['output']
  header: Scalars['String']['output']
}

/** Chat(id, created_at, participant_1, participant_2, last_sent, archived, flagged) */
export type ChatFilter = {
  AND?: InputMaybe<ChatFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<ChatFilter>
  OR?: InputMaybe<ChatFilter>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  flagged?: InputMaybe<Scalars['Boolean']['input']>
}

export type ChatListResponse = {
  __typename?: 'ChatListResponse'
  items: Array<ChatType>
  totalItemsCount: Scalars['Int']['output']
}

export type ChatOrder = {
  createdAt?: InputMaybe<Ordering>
  readAt?: InputMaybe<Ordering>
}

/** ChatTemplate(id, content, order) */
export type ChatTemplateType = {
  __typename?: 'ChatTemplateType'
  content: Scalars['String']['output']
  id: Scalars['ID']['output']
  order: Scalars['Int']['output']
}

/** Chat(id, created_at, participant_1, participant_2, last_sent, archived, flagged) */
export type ChatType = {
  __typename?: 'ChatType'
  archived: Scalars['Boolean']['output']
  flagged: Scalars['Boolean']['output']
  hasUnreadMessage: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  lastSent?: Maybe<Scalars['DateTime']['output']>
  messages: MessageListResponse
  participant1: ParticipantType
  participant2: ParticipantType
}

/** Chat(id, created_at, participant_1, participant_2, last_sent, archived, flagged) */
export type ChatTypeMessagesArgs = {
  filters?: InputMaybe<MessageFilter>
  order?: InputMaybe<MessageOrder>
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

/** Choice(id, sort_order, question, text) */
export type ChoiceType = {
  __typename?: 'ChoiceType'
  id: Scalars['ID']['output']
  /** aka weight */
  sortOrder?: Maybe<Scalars['Int']['output']>
  text: Scalars['String']['output']
  /** position in the answer list and the value of the answer */
  weight?: Maybe<Scalars['Int']['output']>
}

export type CodeLoginInput = {
  emailCode: Scalars['String']['input']
  textCode: Scalars['String']['input']
}

/** Company(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, logo, company_budget, budget_per_employee, max_budget_per_employee, total_seats, account_type) */
export type CompanyFilter = {
  AND?: InputMaybe<CompanyFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<CompanyFilter>
  OR?: InputMaybe<CompanyFilter>
  /** This is the unique identifier for the organization, used to create the URL. */
  slug?: InputMaybe<StrBaseFilterLookup>
}

export type CompanyListResponse = {
  __typename?: 'CompanyListResponse'
  items: Array<CompanyType>
  totalItemsCount: Scalars['Int']['output']
}

/** CompanyMember(id, company, user, is_admin, is_owner, is_employee, approval_status, invited_at, access_expires_at, next_self_scan, first_self_scan_email, first_self_scan_sms, next_check_in, next_check_in_sms, show_check_in_banner, budget, total_budget, work_energy, private_energy, enable_notification) */
export type CompanyMemberData = {
  budget?: InputMaybe<Scalars['Decimal']['input']>
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>
  isEmployee?: InputMaybe<Scalars['Boolean']['input']>
  user: CreateUserInput
}

/** CompanyMember(id, company, user, is_admin, is_owner, is_employee, approval_status, invited_at, access_expires_at, next_self_scan, first_self_scan_email, first_self_scan_sms, next_check_in, next_check_in_sms, show_check_in_banner, budget, total_budget, work_energy, private_energy, enable_notification) */
export type CompanyMemberFilter = {
  AND?: InputMaybe<CompanyMemberFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<CompanyMemberFilter>
  OR?: InputMaybe<CompanyMemberFilter>
  budget?: InputMaybe<DecimalComparisonFilterLookup>
  company?: InputMaybe<CompanyFilter>
  isAdmin?: InputMaybe<BoolBaseFilterLookup>
  isEmployee?: InputMaybe<BoolBaseFilterLookup>
  isOwner?: InputMaybe<BoolBaseFilterLookup>
}

/** CompanyMember(id, company, user, is_admin, is_owner, is_employee, approval_status, invited_at, access_expires_at, next_self_scan, first_self_scan_email, first_self_scan_sms, next_check_in, next_check_in_sms, show_check_in_banner, budget, total_budget, work_energy, private_energy, enable_notification) */
export type CompanyMemberType = {
  __typename?: 'CompanyMemberType'
  budget?: Maybe<Scalars['Decimal']['output']>
  company: CompanyType
  id: Scalars['ID']['output']
  isAdmin: Scalars['Boolean']['output']
  isEmployee: Scalars['Boolean']['output']
  isOwner: Scalars['Boolean']['output']
  privateEnergy?: Maybe<Scalars['Decimal']['output']>
  totalBudget?: Maybe<Scalars['Decimal']['output']>
  workEnergy?: Maybe<Scalars['Decimal']['output']>
}

export type CompanyStatisticsType = {
  __typename?: 'CompanyStatisticsType'
  company: CompanyType
  filteredBookedServices: Scalars['Int']['output']
  mostFavoriteThemes: Array<CompanyThemeType>
  privateEnergy: Scalars['Float']['output']
  seatsInUse: Scalars['Int']['output']
  themes: Array<CompanyThemeType>
  totalAvailableBudget: Scalars['Float']['output']
  totalBookedServices: Scalars['Int']['output']
  totalFinishedCheckin: Scalars['Int']['output']
  totalFinishedSelfscan: Scalars['Int']['output']
  totalPendingBookings: Scalars['Int']['output']
  totalPendingBudget: Scalars['Float']['output']
  totalSeats: Scalars['Int']['output']
  totalSpendBudget: Scalars['Float']['output']
  totalUsersFinishedScan: Scalars['Int']['output']
  workEnergy: Scalars['Float']['output']
}

export type CompanyThemeType = {
  __typename?: 'CompanyThemeType'
  score: Scalars['Float']['output']
  theme: ThemeType
}

/** Company(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, logo, company_budget, budget_per_employee, max_budget_per_employee, total_seats, account_type) */
export type CompanyType = {
  __typename?: 'CompanyType'
  accountType: OrganizationsCompanyAccountTypeEnum
  addressLine1: Scalars['String']['output']
  addressLine2: Scalars['String']['output']
  /** Sets the budget for new employees of this company. Updating this value doesn't affect existing employees */
  budgetPerEmployee: Scalars['Decimal']['output']
  city: Scalars['String']['output']
  cocNumber: Scalars['String']['output']
  companyBudget: Scalars['Decimal']['output']
  id: Scalars['ID']['output']
  logo?: Maybe<Image>
  /** Sets the max budget that HR can set for an employee. Updating this value doesn't affect existing employees */
  maxBudgetPerEmployee: Scalars['Decimal']['output']
  name: Scalars['String']['output']
  postcode: Scalars['String']['output']
  province: OrganizationsCompanyProvinceEnum
  registeredMembers: Scalars['Int']['output']
  seatsInUse: Scalars['Int']['output']
  /** This is the unique identifier for the organization, used to create the URL. */
  slug: Scalars['String']['output']
  totalSeats: Scalars['Int']['output']
  website: Scalars['String']['output']
}

/** Company(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, logo, company_budget, budget_per_employee, max_budget_per_employee, total_seats, account_type) */
export type CompanyTypeLogoArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type CompanyTypeVendorType = CompanyType | VendorType

export type ContactListResponse = {
  __typename?: 'ContactListResponse'
  items: Array<ContactType>
  totalItemsCount: Scalars['Int']['output']
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type ContactType = {
  __typename?: 'ContactType'
  companyMember?: Maybe<CompanyMemberType>
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  email: Scalars['String']['output']
  familyStatus: UsersUserFamilyStatusEnum
  firstName: Scalars['String']['output']
  gender: UsersUserGenderEnum
  id: Scalars['ID']['output']
  isCustomerSupport: Scalars['Boolean']['output']
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output']
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output']
  language: UsersUserLanguageEnum
  lastLogin?: Maybe<Scalars['DateTime']['output']>
  lastName: Scalars['String']['output']
  nonThemeAnswers?: Maybe<Array<ConvertedAnswer>>
  phoneNumber?: Maybe<Scalars['String']['output']>
  userThemes: Array<UserThemeType>
  vendorMember?: Maybe<VendorMemberType>
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type ContactTypeUserThemesArgs = {
  filters?: InputMaybe<UserThemeFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** ContentPage(id, title, slug, main_image, intro, body, date, author, vendor) */
export type ContentPageFilter = {
  AND?: InputMaybe<ContentPageFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<ContentPageFilter>
  OR?: InputMaybe<ContentPageFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ContentPageListResponse = {
  __typename?: 'ContentPageListResponse'
  items: Array<ContentPageType>
  totalItemsCount: Scalars['Int']['output']
}

export type ContentPageOrder = {
  date?: InputMaybe<Ordering>
  title?: InputMaybe<Ordering>
}

/** ContentPage(id, title, slug, main_image, intro, body, date, author, vendor) */
export type ContentPageType = {
  __typename?: 'ContentPageType'
  author?: Maybe<Scalars['String']['output']>
  body: Scalars['HTML']['output']
  child?: Maybe<BlogTypeGuideType>
  date: Scalars['Date']['output']
  id: Scalars['ID']['output']
  images?: Maybe<Array<Image>>
  intro: Scalars['String']['output']
  mainImage?: Maybe<Image>
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  themes: Array<ThemeType>
  title: Scalars['String']['output']
  vendor?: Maybe<VendorType>
}

/** ContentPage(id, title, slug, main_image, intro, body, date, author, vendor) */
export type ContentPageTypeImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** ContentPage(id, title, slug, main_image, intro, body, date, author, vendor) */
export type ContentPageTypeMainImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type ConvertedAnswer = {
  __typename?: 'ConvertedAnswer'
  answer: Scalars['String']['output']
  questionId: Scalars['ID']['output']
  text: Scalars['String']['output']
}

/** Booking(id, created_at, updated_at, price, status, employee, product, date, employee_comment, vendor_billing_status, company_billing_status) */
export type CreateBookingInput = {
  /** For services: current proposal for when it should take place */
  date?: InputMaybe<Scalars['Date']['input']>
  employeeComment?: InputMaybe<Scalars['String']['input']>
  productId: Scalars['ID']['input']
}

export type CreateBookingPayload = BookingType | OperationInfo

/** Input type for sending a message. Only one of the 'to' fields should be set. */
export type CreateChatInput = {
  toCompanyMember?: InputMaybe<Scalars['ID']['input']>
  toThriveCs?: InputMaybe<Scalars['Boolean']['input']>
  toVendor?: InputMaybe<Scalars['ID']['input']>
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type CreateUserInput = {
  email: Scalars['Email']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<UsersUserLanguageEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

export type Dashboard = {
  __typename?: 'Dashboard'
  contentPages: Array<ContentPageType>
  showScan: ShowScanOptions
  themes: Array<ThemeType>
  user: UserType
  userThemes: Array<UserThemeType>
}

export type DateDateFilterLookup = {
  day?: InputMaybe<IntComparisonFilterLookup>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Date']['input']>
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['Date']['input']>
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['Date']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Date']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  isoWeekDay?: InputMaybe<IntComparisonFilterLookup>
  isoYear?: InputMaybe<IntComparisonFilterLookup>
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['Date']['input']>
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['Date']['input']>
  month?: InputMaybe<IntComparisonFilterLookup>
  quarter?: InputMaybe<IntComparisonFilterLookup>
  /** Inclusive range test (between) */
  range?: InputMaybe<DateRangeLookup>
  week?: InputMaybe<IntComparisonFilterLookup>
  weekDay?: InputMaybe<IntComparisonFilterLookup>
  year?: InputMaybe<IntComparisonFilterLookup>
}

export type DateRangeLookup = {
  end?: InputMaybe<Scalars['Date']['input']>
  start?: InputMaybe<Scalars['Date']['input']>
}

export type DatetimeDatetimeFilterLookup = {
  date?: InputMaybe<IntComparisonFilterLookup>
  day?: InputMaybe<IntComparisonFilterLookup>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['DateTime']['input']>
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  hour?: InputMaybe<IntComparisonFilterLookup>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['DateTime']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  isoWeekDay?: InputMaybe<IntComparisonFilterLookup>
  isoYear?: InputMaybe<IntComparisonFilterLookup>
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  minute?: InputMaybe<IntComparisonFilterLookup>
  month?: InputMaybe<IntComparisonFilterLookup>
  quarter?: InputMaybe<IntComparisonFilterLookup>
  /** Inclusive range test (between) */
  range?: InputMaybe<DatetimeRangeLookup>
  second?: InputMaybe<IntComparisonFilterLookup>
  time?: InputMaybe<IntComparisonFilterLookup>
  week?: InputMaybe<IntComparisonFilterLookup>
  weekDay?: InputMaybe<IntComparisonFilterLookup>
  year?: InputMaybe<IntComparisonFilterLookup>
}

export type DatetimeRangeLookup = {
  end?: InputMaybe<Scalars['DateTime']['input']>
  start?: InputMaybe<Scalars['DateTime']['input']>
}

export type DecimalComparisonFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Decimal']['input']>
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['Decimal']['input']>
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['Decimal']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Decimal']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['Decimal']['input']>
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['Decimal']['input']>
  /** Inclusive range test (between) */
  range?: InputMaybe<DecimalRangeLookup>
}

export type DecimalRangeLookup = {
  end?: InputMaybe<Scalars['Decimal']['input']>
  start?: InputMaybe<Scalars['Decimal']['input']>
}

export type DjangoModelFilterInput = {
  pk: Scalars['ID']['input']
}

export type DjangoModelType = {
  __typename?: 'DjangoModelType'
  pk: Scalars['ID']['output']
}

export type EmailLoginInfoInput = {
  emailCode: Scalars['String']['input']
}

/** CompanyMember(id, company, user, is_admin, is_owner, is_employee, approval_status, invited_at, access_expires_at, next_self_scan, first_self_scan_email, first_self_scan_sms, next_check_in, next_check_in_sms, show_check_in_banner, budget, total_budget, work_energy, private_energy, enable_notification) */
export type Employee = {
  __typename?: 'Employee'
  budget?: Maybe<Scalars['Decimal']['output']>
  company: CompanyType
  id: Scalars['ID']['output']
  inactive: Scalars['Boolean']['output']
  user: UserType
}

export type EmployeeListResponse = {
  __typename?: 'EmployeeListResponse'
  items: Array<Employee>
  totalItemsCount: Scalars['Int']['output']
}

export type ExportedScanListResponse = {
  __typename?: 'ExportedScanListResponse'
  items: Array<ExportedScanType>
  totalItemsCount: Scalars['Int']['output']
}

export type ExportedScanType = {
  __typename?: 'ExportedScanType'
  bookedProducts: Scalars['String']['output']
  date: Scalars['String']['output']
  organizationId: Scalars['ID']['output']
  scanType: Scalars['String']['output']
  scoredQuestions: Array<ScoreType>
  scoredThemes: Array<ScoreType>
  userId: Scalars['ID']['output']
}

export enum FamilyStatusChoices {
  InRelationshipNoChildren = 'IN_RELATIONSHIP_NO_CHILDREN',
  InRelationshipWithChildren = 'IN_RELATIONSHIP_WITH_CHILDREN',
  Single = 'SINGLE',
  SingleParent = 'SINGLE_PARENT',
}

/** Faq(id, question, answer, order) */
export type Faq = {
  __typename?: 'Faq'
  answer: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The order of the FAQs displayed on the page in ascending order */
  order: Scalars['Int']['output']
  question: Scalars['String']['output']
}

/** FavoriteItem(id, type, user, product, vendor, content_page) */
export type FavoriteInput = {
  itemId: Scalars['String']['input']
  itemType: FavoriteItemType
}

/** FavoriteItem(id, type, user, product, vendor, content_page) */
export type FavoriteItem = {
  __typename?: 'FavoriteItem'
  id: Scalars['ID']['output']
  itemId: Scalars['String']['output']
  type: FavoritesFavoriteItemTypeEnum
}

export enum FavoriteItemType {
  ContentPage = 'content_page',
  Product = 'product',
  Vendor = 'vendor',
}

/** favorite item | type */
export enum FavoritesFavoriteItemTypeEnum {
  /** Content Page */
  ContentPage = 'content_page',
  /** Product */
  Product = 'product',
  /** Vendor */
  Vendor = 'vendor',
}

export type FillInRegisterInfoPayload = OperationInfo | UserType

/** Resize and crop to fill the exact dimensions specified */
export type FillInput = {
  crop?: InputMaybe<Scalars['Int']['input']>
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

export type FinishScanInput = {
  scanId: Scalars['ID']['input']
}

export type FinishScanType = {
  __typename?: 'FinishScanType'
  id: Scalars['ID']['output']
  needSupport: Scalars['Boolean']['output']
}

/** FreeTierBudgetRequest(id, created_at, updated_at, employee, approved_by_staff, amount) */
export type FreeTierBudgetRequestInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>
  employeeId: Scalars['ID']['input']
}

export enum GenderChoices {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSay = 'NOT_SAY',
  Other = 'OTHER',
}

export type GuideListResponse = {
  __typename?: 'GuideListResponse'
  items: Array<GuideType>
  totalItemsCount: Scalars['Int']['output']
}

/** Guide(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr, chapters, footer_icon, footer_header, footer_text) */
export type GuideType = {
  __typename?: 'GuideType'
  author?: Maybe<Scalars['String']['output']>
  body: Scalars['HTML']['output']
  chapters: Array<ChapterType>
  child?: Maybe<BlogTypeGuideType>
  date: Scalars['Date']['output']
  footerHeader?: Maybe<Scalars['String']['output']>
  footerIcon?: Maybe<Image>
  footerText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  images?: Maybe<Array<Image>>
  intro: Scalars['String']['output']
  mainImage?: Maybe<Image>
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  themes: Array<ThemeType>
  title: Scalars['String']['output']
  vendor?: Maybe<VendorType>
}

/** Guide(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr, chapters, footer_icon, footer_header, footer_text) */
export type GuideTypeFooterIconArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Guide(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr, chapters, footer_icon, footer_header, footer_text) */
export type GuideTypeImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Guide(id, title, slug, main_image, intro, body, date, author, vendor, contentpage_ptr, chapters, footer_icon, footer_header, footer_text) */
export type GuideTypeMainImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Reduces the height of the image to the dimension specified */
export type HeightInput = {
  value: Scalars['Int']['input']
}

/** Highlight(id, sort_order, theme, product, vendor, content_page) */
export type HighlightType = {
  __typename?: 'HighlightType'
  highlightedItem: ProductTypeVendorTypeContentPageType
}

export type IdBaseFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['ID']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type Image = {
  __typename?: 'Image'
  alt: Scalars['String']['output']
  baseImageId: Scalars['ID']['output']
  height: Scalars['Int']['output']
  id: Scalars['ID']['output']
  path: Scalars['String']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

/** CompanyMember(id, company, user, is_admin, is_owner, is_employee, approval_status, invited_at, access_expires_at, next_self_scan, first_self_scan_email, first_self_scan_sms, next_check_in, next_check_in_sms, show_check_in_banner, budget, total_budget, work_energy, private_energy, enable_notification) */
export type ImportCompanyMembers = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  members: Array<CompanyMemberData>
}

export type ImportResult = {
  __typename?: 'ImportResult'
  errorCount: Scalars['Int']['output']
  errorItems: Array<BulkUploadError>
  successCount: Scalars['Int']['output']
}

export type IntComparisonFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Int']['input']>
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['Int']['input']>
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['Int']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Int']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['Int']['input']>
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['Int']['input']>
  /** Inclusive range test (between) */
  range?: InputMaybe<IntRangeLookup>
}

export type IntRangeLookup = {
  end?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

/** Item(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr) */
export type ItemType = {
  __typename?: 'ItemType'
  child?: Maybe<ItemTypeServiceType>
  /** Appears on the cards or on product/service detail page when no rich description is provided. */
  description: Scalars['String']['output']
  descriptionImages?: Maybe<Array<Image>>
  id: Scalars['ID']['output']
  /** Used when template is SINGLE_IMAGE, SINGLE_BIG_IMAGE or BOOK */
  image?: Maybe<Image>
  images?: Maybe<Array<ProductImagesType>>
  name: Scalars['String']['output']
  price: Scalars['Decimal']['output']
  priceAlterText?: Maybe<ProductsProductPriceAlterTextEnum>
  /** Rich text with more details, images, videos... */
  richDescription: Scalars['HTML']['output']
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  template: ProductsProductTemplateEnum
  themes: Array<ThemeType>
  usePlaceholderImage: Scalars['Boolean']['output']
  vendor: VendorType
}

/** Item(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr) */
export type ItemTypeDescriptionImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Item(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr) */
export type ItemTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type ItemTypeServiceType = ItemType | ServiceType

/** Language(id, code) */
export type LanguageType = {
  __typename?: 'LanguageType'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

/** Location(id, name, category) */
export type LocationFilter = {
  AND?: InputMaybe<LocationFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<LocationFilter>
  OR?: InputMaybe<LocationFilter>
  id?: InputMaybe<IdBaseFilterLookup>
  name?: InputMaybe<StrFilterLookup>
}

/** LocationGroup(id, name) */
export type LocationGroupFilter = {
  AND?: InputMaybe<LocationGroupFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<LocationGroupFilter>
  OR?: InputMaybe<LocationGroupFilter>
  id?: InputMaybe<IdBaseFilterLookup>
  locations?: InputMaybe<LocationFilter>
  name?: InputMaybe<StrFilterLookup>
}

/** LocationGroup(id, name) */
export type LocationGroupType = {
  __typename?: 'LocationGroupType'
  id: Scalars['ID']['output']
  locations: Array<LocationType>
  name: Scalars['String']['output']
}

/** Location(id, name, category) */
export type LocationType = {
  __typename?: 'LocationType'
  category?: Maybe<LocationsLocationCategoryEnum>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** location | category */
export enum LocationsLocationCategoryEnum {
  /** Province */
  Province = 'Province',
  /** Region */
  Region = 'Region',
}

export type LoginInfo = {
  __typename?: 'LoginInfo'
  organization: CompanyTypeVendorType
  user: UserType
}

export type MarketingWebsiteInput = {
  email: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['String']['input']>
  organizationSize?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
}

/** Fit within the given dimensions */
export type MaxInput = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

/** Message(id, created_at, read_at, automated_message, sent_email_at, user, sender, receiver, chat, content, type, archived, marked_unread, flagged) */
export type MessageFilter = {
  AND?: InputMaybe<MessageFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<MessageFilter>
  OR?: InputMaybe<MessageFilter>
  archived?: InputMaybe<Scalars['Boolean']['input']>
  flagged?: InputMaybe<Scalars['Boolean']['input']>
  markedUnread?: InputMaybe<Scalars['Boolean']['input']>
}

export type MessageListResponse = {
  __typename?: 'MessageListResponse'
  items: Array<MessageType>
  totalItemsCount: Scalars['Int']['output']
}

export type MessageOrder = {
  createdAt?: InputMaybe<Ordering>
  readAt?: InputMaybe<Ordering>
}

/** Message(id, created_at, read_at, automated_message, sent_email_at, user, sender, receiver, chat, content, type, archived, marked_unread, flagged) */
export type MessageType = {
  __typename?: 'MessageType'
  archived: Scalars['Boolean']['output']
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  direction: Scalars['String']['output']
  flagged: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  markedUnread: Scalars['Boolean']['output']
  readAt?: Maybe<Scalars['DateTime']['output']>
  receiver: DjangoModelType
  sender: DjangoModelType
  senderEmail: Scalars['String']['output']
  sentEmailAt?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<Scalars['String']['output']>
  user?: Maybe<DjangoModelType>
}

/** Cover the given dimensions */
export type MinInput = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

export type MiniSearchResponse = {
  __typename?: 'MiniSearchResponse'
  blogs: Array<SearchItemType>
  experts: Array<SearchItemType>
  products: Array<SearchItemType>
}

/** MostChosen(id, text, order) */
export type MostChosenType = {
  __typename?: 'MostChosenType'
  id: Scalars['ID']['output']
  linkedItems: Array<Scalars['String']['output']>
  order: Scalars['Int']['output']
  text: Scalars['String']['output']
}

export type MultipleChoiceAnswerInput = {
  answer: Scalars['Int']['input']
  questionId: Scalars['ID']['input']
}

/** MultipleChoiceQuestion(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image, question_ptr) */
export type MultipleChoiceQuestionType = {
  __typename?: 'MultipleChoiceQuestionType'
  choices: Array<ChoiceType>
  id: Scalars['ID']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Accept The Purchase Agreement */
  acceptPurchaseAgreement: Terms
  /** Accept Terms of Service */
  acceptTerms: Terms
  adminBookingApproval: AdminBookingApprovalPayload
  answerBinaryQuestion: Scalars['OK']['output']
  answerMultipleChoiceQuestion: Scalars['OK']['output']
  answerScoreQuestion: Scalars['OK']['output']
  answerSliderQuestion: Scalars['OK']['output']
  answerTextQuestion: Scalars['OK']['output']
  bookingDateApproval: BookingDateApprovalPayload
  budgetCompanyApproval: Scalars['OK']['output']
  budgetRequest: Scalars['OK']['output']
  bulkUpdateEmployeeBudget: Scalars['OK']['output']
  contactFormMutation: Scalars['OK']['output']
  createBooking: CreateBookingPayload
  createCampaign: CampaignType
  createCampaignMessageTemplate: CampaignMessageTemplateType
  createChat: ChatType
  createCompanyEmployee: Scalars['OK']['output']
  createFavorite: Scalars['OK']['output']
  createSegment: SegmentType
  createSendgridSegments: Scalars['Boolean']['output']
  deleteCampaign: Scalars['Boolean']['output']
  deleteCampaignMessageTemplate: Scalars['Boolean']['output']
  deleteCompanyEmployee: Scalars['OK']['output']
  deleteFavorite: Scalars['OK']['output']
  deleteSegment: Scalars['Boolean']['output']
  downloadInterviewMutation: Scalars['OK']['output']
  fillInRegisterInfo: FillInRegisterInfoPayload
  finishScan: FinishScanType
  freeTierBudgetRequest: Scalars['OK']['output']
  importCompanyMemberMutation: ImportResult
  /** Login Step 3: Submit text & email code to login */
  login?: Maybe<UserType>
  loginWithPassword?: Maybe<UserType>
  logout: Scalars['Boolean']['output']
  markSupportedUser: Scalars['Boolean']['output']
  readChat: ChatType
  registerWebinar: Scalars['Boolean']['output']
  /** Login Step 1: Request an email with magic link */
  requestEmailLoginCode: Scalars['OK']['output']
  requestScanMutation: Scalars['OK']['output']
  /** Login Step 2: Submit the email login code to receive a text code */
  requestTextLoginCode: Scalars['OK']['output']
  requestWhitepaperMutation: Scalars['OK']['output']
  sendEmailForMessage: Scalars['OK']['output']
  sendEmailForMessageByAdmin: Scalars['OK']['output']
  sendInvite: Scalars['OK']['output']
  sendMessage: MessageType
  shareInfoApproval: Scalars['Boolean']['output']
  staffBookingApproval: StaffBookingApprovalPayload
  startScan: Scalars['OK']['output']
  suggestBookingDate: SuggestBookingDatePayload
  synchronizeUsersToSendgrid: Scalars['Int']['output']
  updateBillingStatus: UpdateBillingStatusPayload
  updateCampaign: CampaignType
  updateCampaignMessageTemplate: CampaignMessageTemplateType
  updateChat: ChatType
  updateCompanyEmployee: Scalars['OK']['output']
  updateFavoriteTheme: Scalars['OK']['output']
  updateLanguage: UpdateLanguagePayload
  updateMessage: MessageType
  updateProfile: UpdateProfilePayload
  updateSegment: SegmentType
  vendorBookingApproval: VendorBookingApprovalPayload
}

export type MutationAcceptPurchaseAgreementArgs = {
  input: AcceptTermsInput
}

export type MutationAcceptTermsArgs = {
  input: AcceptTermsInput
}

export type MutationAdminBookingApprovalArgs = {
  input: ApproveBookingInput
}

export type MutationAnswerBinaryQuestionArgs = {
  input: BinaryAnswerInput
}

export type MutationAnswerMultipleChoiceQuestionArgs = {
  input: MultipleChoiceAnswerInput
}

export type MutationAnswerScoreQuestionArgs = {
  input: ScoreAnswerInput
}

export type MutationAnswerSliderQuestionArgs = {
  input: SliderAnswerInput
}

export type MutationAnswerTextQuestionArgs = {
  input: TextAnswerInput
}

export type MutationBookingDateApprovalArgs = {
  input: ApproveDateInput
}

export type MutationBudgetCompanyApprovalArgs = {
  input: BudgetCompanyApprovalInput
}

export type MutationBudgetRequestArgs = {
  input: BudgetRequestInput
}

export type MutationBulkUpdateEmployeeBudgetArgs = {
  budget: Scalars['Float']['input']
  slug: Scalars['String']['input']
}

export type MutationContactFormMutationArgs = {
  input: MarketingWebsiteInput
}

export type MutationCreateBookingArgs = {
  input: CreateBookingInput
}

export type MutationCreateCampaignArgs = {
  input: CampaignInput
}

export type MutationCreateCampaignMessageTemplateArgs = {
  input: CampaignMessageTemplateInput
}

export type MutationCreateChatArgs = {
  input: CreateChatInput
}

export type MutationCreateCompanyEmployeeArgs = {
  budget?: InputMaybe<Scalars['Float']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  input: CreateUserInput
}

export type MutationCreateFavoriteArgs = {
  input: FavoriteInput
}

export type MutationCreateSegmentArgs = {
  input: SegmentInput
}

export type MutationDeleteCampaignArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCampaignMessageTemplateArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCompanyEmployeeArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteFavoriteArgs = {
  input: FavoriteInput
}

export type MutationDeleteSegmentArgs = {
  id: Scalars['ID']['input']
}

export type MutationDownloadInterviewMutationArgs = {
  input: MarketingWebsiteInput
}

export type MutationFillInRegisterInfoArgs = {
  code: Scalars['String']['input']
  input: UserInput
}

export type MutationFinishScanArgs = {
  input: FinishScanInput
}

export type MutationFreeTierBudgetRequestArgs = {
  input: FreeTierBudgetRequestInput
}

export type MutationImportCompanyMemberMutationArgs = {
  data: ImportCompanyMembers
}

export type MutationLoginArgs = {
  input: CodeLoginInput
}

export type MutationLoginWithPasswordArgs = {
  input: PasswordLoginInput
}

export type MutationMarkSupportedUserArgs = {
  userId: Scalars['ID']['input']
}

export type MutationReadChatArgs = {
  input: ReadChatInput
}

export type MutationRegisterWebinarArgs = {
  id: Scalars['ID']['input']
}

export type MutationRequestEmailLoginCodeArgs = {
  input: RequestEmailLoginCodeInput
}

export type MutationRequestScanMutationArgs = {
  input: MarketingWebsiteInput
}

export type MutationRequestTextLoginCodeArgs = {
  input: RequestTextLoginCodeInput
}

export type MutationRequestWhitepaperMutationArgs = {
  input: MarketingWebsiteInput
}

export type MutationSendEmailForMessageArgs = {
  id: Scalars['ID']['input']
}

export type MutationSendEmailForMessageByAdminArgs = {
  id: Scalars['ID']['input']
}

export type MutationSendInviteArgs = {
  employeeId: Scalars['ID']['input']
}

export type MutationSendMessageArgs = {
  input: SendMessageInput
}

export type MutationShareInfoApprovalArgs = {
  input: ShareInfoApproval
}

export type MutationStaffBookingApprovalArgs = {
  input: ApproveBookingInput
}

export type MutationStartScanArgs = {
  input: StartScanInput
}

export type MutationSuggestBookingDateArgs = {
  input: SuggestDateInput
}

export type MutationUpdateBillingStatusArgs = {
  input: UpdateBillingStatusInput
}

export type MutationUpdateCampaignArgs = {
  id: Scalars['ID']['input']
  input: CampaignInput
}

export type MutationUpdateCampaignMessageTemplateArgs = {
  id: Scalars['ID']['input']
  input: CampaignMessageTemplateInput
}

export type MutationUpdateChatArgs = {
  id: Scalars['ID']['input']
  input: UpdateChatInput
}

export type MutationUpdateCompanyEmployeeArgs = {
  budget?: InputMaybe<Scalars['Float']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  input: CreateUserInput
}

export type MutationUpdateFavoriteThemeArgs = {
  input: UpdateThemeInput
}

export type MutationUpdateLanguageArgs = {
  input: UpdateLanguageInput
}

export type MutationUpdateMessageArgs = {
  id: Scalars['ID']['input']
  input: UpdateMessageInput
}

export type MutationUpdateProfileArgs = {
  input: UserInput
}

export type MutationUpdateSegmentArgs = {
  id: Scalars['ID']['input']
  input: SegmentInput
}

export type MutationVendorBookingApprovalArgs = {
  input: ApproveBookingInput
}

export type OffsetPaginationInput = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type OperationInfo = {
  __typename?: 'OperationInfo'
  /** List of messages returned by the operation. */
  messages: Array<OperationMessage>
}

export type OperationMessage = {
  __typename?: 'OperationMessage'
  /** The error code, or `null` if no error code was set. */
  code?: Maybe<Scalars['String']['output']>
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']['output']>
  /** The kind of this message. */
  kind: OperationMessageKind
  /** The error message. */
  message: Scalars['String']['output']
}

export enum OperationMessageKind {
  Error = 'ERROR',
  Info = 'INFO',
  Permission = 'PERMISSION',
  Validation = 'VALIDATION',
  Warning = 'WARNING',
}

export enum Ordering {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** company | account type */
export enum OrganizationsCompanyAccountTypeEnum {
  /** Free */
  Free = 'FREE',
  /** Light */
  Light = 'LIGHT',
  /** Normal */
  Normal = 'NORMAL',
}

/** company | province */
export enum OrganizationsCompanyProvinceEnum {
  /** Drenthe */
  Drenthe = 'Drenthe',
  /** Flevoland */
  Flevoland = 'Flevoland',
  /** Friesland */
  Friesland = 'Friesland',
  /** Gelderland */
  Gelderland = 'Gelderland',
  /** Groningen */
  Groningen = 'Groningen',
  /** Limburg */
  Limburg = 'Limburg',
  /** Noord-Brabant */
  NoordBrabant = 'Noord_Brabant',
  /** Noord-Holland */
  NoordHolland = 'Noord_Holland',
  /** Overijssel */
  Overijssel = 'Overijssel',
  /** Utrecht */
  Utrecht = 'Utrecht',
  /** Zeeland */
  Zeeland = 'Zeeland',
  /** Zuid-Holland */
  ZuidHolland = 'Zuid_Holland',
}

/** timeline item | item type */
export enum OrganizationsTimelineItemItemTypeEnum {
  /** Booking Finished */
  BookingFinished = 'booking_finished',
  /** Booking Planned */
  BookingPlanned = 'booking_planned',
  /** Check In Finished */
  CheckInFinished = 'check_in_finished',
  /** Check In Planned */
  CheckInPlanned = 'check_in_planned',
  /** Self Scan Finished */
  SelfScanFinished = 'self_scan_finished',
  /** Self Scan Planned */
  SelfScanPlanned = 'self_scan_planned',
}

/** vendor | province */
export enum OrganizationsVendorProvinceEnum {
  /** Drenthe */
  Drenthe = 'Drenthe',
  /** Flevoland */
  Flevoland = 'Flevoland',
  /** Friesland */
  Friesland = 'Friesland',
  /** Gelderland */
  Gelderland = 'Gelderland',
  /** Groningen */
  Groningen = 'Groningen',
  /** Limburg */
  Limburg = 'Limburg',
  /** Noord-Brabant */
  NoordBrabant = 'Noord_Brabant',
  /** Noord-Holland */
  NoordHolland = 'Noord_Holland',
  /** Overijssel */
  Overijssel = 'Overijssel',
  /** Utrecht */
  Utrecht = 'Utrecht',
  /** Zeeland */
  Zeeland = 'Zeeland',
  /** Zuid-Holland */
  ZuidHolland = 'Zuid_Holland',
}

/** Original image */
export enum OriginalInput {
  Original = 'original',
}

export type OverviewStatisticsType = {
  __typename?: 'OverviewStatisticsType'
  bookedServices: Scalars['Int']['output']
  filteredBookings: Scalars['Int']['output']
  mostFavoriteThemes: Array<CompanyThemeType>
  privateEnergy: Scalars['Float']['output']
  registrations: Scalars['Int']['output']
  seatsInUse: Scalars['Int']['output']
  themes: Array<CompanyThemeType>
  totalAvailableBudget: Scalars['Float']['output']
  totalFinishedCheckin: Scalars['Int']['output']
  totalFinishedSelfscan: Scalars['Int']['output']
  totalPendingBookings: Scalars['Int']['output']
  totalPendingBudget: Scalars['Float']['output']
  totalSeats: Scalars['Int']['output']
  totalSpendBudget: Scalars['Float']['output']
  workEnergy: Scalars['Float']['output']
}

/** ChatParticipant(id, created_at, company_member, vendor, is_thrive_cs, is_thrive_system) */
export type ParticipantType = {
  __typename?: 'ParticipantType'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  isCurrentUser: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type PasswordLoginInput = {
  email: Scalars['Email']['input']
  password: Scalars['String']['input']
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPage = {
  __typename?: 'ProductDetailPage'
  contentPages: ContentPageListResponse
  description: Scalars['String']['output']
  descriptionHeader: Scalars['String']['output']
  highlights: Array<HighlightType>
  icon?: Maybe<Image>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  productTags: Array<TagType>
  products: ProductListResponse
  /** @deprecated Replaced by score */
  relevance: Scalars['Float']['output']
  score: Scalars['Float']['output']
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  vendors: VendorListResponse
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPageContentPagesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPageHighlightsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPageIconArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPageProductsArgs = {
  filters?: InputMaybe<ProductFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPageTagsArgs = {
  filters?: InputMaybe<TagFilter>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ProductDetailPageVendorsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** Product(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image) */
export type ProductFilter = {
  AND?: InputMaybe<ProductFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<ProductFilter>
  OR?: InputMaybe<ProductFilter>
  id?: InputMaybe<IdBaseFilterLookup>
  name?: InputMaybe<StrFilterLookup>
  price?: InputMaybe<DecimalComparisonFilterLookup>
  service?: InputMaybe<ServiceFilter>
  /** A comma-separated list of tags. */
  tags?: InputMaybe<TagFilter>
  vendor?: InputMaybe<VendorFilter>
}

/** ProductImages(id, sort_order, product, image) */
export type ProductImagesType = {
  __typename?: 'ProductImagesType'
  image?: Maybe<Image>
}

/** ProductImages(id, sort_order, product, image) */
export type ProductImagesTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type ProductListResponse = {
  __typename?: 'ProductListResponse'
  items: Array<ProductType>
  totalItemsCount: Scalars['Int']['output']
}

/** Product(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image) */
export type ProductType = {
  __typename?: 'ProductType'
  child?: Maybe<ItemTypeServiceType>
  /** Appears on the cards or on product/service detail page when no rich description is provided. */
  description: Scalars['String']['output']
  descriptionImages?: Maybe<Array<Image>>
  id: Scalars['ID']['output']
  /** Used when template is SINGLE_IMAGE, SINGLE_BIG_IMAGE or BOOK */
  image?: Maybe<Image>
  images?: Maybe<Array<ProductImagesType>>
  name: Scalars['String']['output']
  price: Scalars['Decimal']['output']
  priceAlterText?: Maybe<ProductsProductPriceAlterTextEnum>
  /** Rich text with more details, images, videos... */
  richDescription: Scalars['HTML']['output']
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  template: ProductsProductTemplateEnum
  themes: Array<ThemeType>
  usePlaceholderImage: Scalars['Boolean']['output']
  vendor: VendorType
}

/** Product(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image) */
export type ProductTypeDescriptionImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Product(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image) */
export type ProductTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type ProductTypeVendorTypeContentPageType = ContentPageType | ProductType | VendorType

/** product | price alter text */
export enum ProductsProductPriceAlterTextEnum {
  /** Free */
  Free = 'FREE',
  /** On Request */
  OnRequest = 'ON_REQUEST',
}

/** product | template */
export enum ProductsProductTemplateEnum {
  /** Book */
  Book = 'BOOK',
  /** Multi Images */
  MultiImages = 'MULTI_IMAGES',
  /** Single Big Image */
  SingleBigImage = 'SINGLE_BIG_IMAGE',
  /** Single Image */
  SingleImage = 'SINGLE_IMAGE',
}

export type Query = {
  __typename?: 'Query'
  activeWebinars: WebinarListResponse
  blog: BlogType
  blogs: BlogListResponse
  booking: BookingType
  bookings: BookingListResponse
  budgetRequests: BudgetRequestListResponse
  campaignDetail: CampaignType
  campaignMessageTemplates: CampaignMessageTemplateListResponse
  campaignUsers: UserListResponse
  campaigns: CampaignListResponse
  chat: ChatType
  chatTemplates: Array<ChatTemplateType>
  chats: ChatListResponse
  checkIn?: Maybe<ScanType>
  companies: CompanyListResponse
  company: CompanyType
  companyMembers: EmployeeListResponse
  companyStatistics: CompanyStatisticsType
  contentPages: Array<ContentPageType>
  dashboard: Dashboard
  emailLoginInfo: LoginInfo
  exportScans: ExportedScanListResponse
  faq: Faq
  faqs: Array<Faq>
  favoriteContentPages: ContentPageListResponse
  favoriteProducts: ProductListResponse
  favoriteVendors: VendorListResponse
  favorites: Array<FavoriteItem>
  guide: GuideType
  guides: GuideListResponse
  hasUnreadChat: UnreadChatType
  locations: Array<LocationType>
  me?: Maybe<UserType>
  miniSearch: MiniSearchResponse
  mostChosenSearches: Array<MostChosenType>
  needSupportUsers: UserListResponse
  nonFavoriteUserThemes: Array<UserThemeType>
  notFollowedChats: ChatListResponse
  overviewStatistics: OverviewStatisticsType
  product: ProductType
  productDetailPage: ProductDetailPage
  products: ProductListResponse
  /** Get the latest purchase agreement of service */
  purchaseAgreement: Terms
  search: SearchItemListResponse
  searchHistory: Array<SearchHistoryType>
  segmentDetail: SegmentType
  segments: SegmentListResponse
  selfScan?: Maybe<ScanType>
  service: ServiceType
  services: Array<ServiceType>
  sharedUserInfo: SharedUserInfoType
  sharedUserInfoList: SharedUserInfoListResponse
  tagPage: TagPageType
  tagPages: Array<TagPageType>
  tags: Array<TagType>
  /** Get the latest terms of service */
  terms: Terms
  testLoginAuth: Scalars['Boolean']['output']
  testOrgAdminAuth: Scalars['Boolean']['output']
  testOrgEmployeeAuth: Scalars['Boolean']['output']
  testOrgOwnerAuth: Scalars['Boolean']['output']
  testVendorAuth: Scalars['Boolean']['output']
  theme: ThemeType
  themes: Array<ThemeType>
  timeline: TimeLineItemListResponse
  userInfo: UserType
  vendor: VendorType
  vendorStatistics: VendorStatisticsType
  vendorWithoutUserChats: ChatListResponse
  vendors: VendorListResponse
}

export type QueryActiveWebinarsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryBlogArgs = {
  slug: Scalars['String']['input']
}

export type QueryBlogsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryBookingArgs = {
  pk: Scalars['ID']['input']
}

export type QueryBookingsArgs = {
  filters?: InputMaybe<BookingFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryBudgetRequestsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type QueryCampaignDetailArgs = {
  id: Scalars['ID']['input']
}

export type QueryCampaignMessageTemplatesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryCampaignUsersArgs = {
  filters?: InputMaybe<UserFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryCampaignsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryChatArgs = {
  pk: Scalars['ID']['input']
}

export type QueryChatsArgs = {
  filters?: InputMaybe<ChatFilter>
  order?: InputMaybe<ChatOrder>
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryCompaniesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompanyArgs = {
  slug?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompanyMembersArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
  selfscan?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompanyStatisticsArgs = {
  dateRange?: InputMaybe<StatisticsDateRange>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type QueryContentPagesArgs = {
  filters?: InputMaybe<ContentPageFilter>
  order?: InputMaybe<ContentPageOrder>
}

export type QueryEmailLoginInfoArgs = {
  input: EmailLoginInfoInput
}

export type QueryExportScansArgs = {
  excludeIds?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryFaqArgs = {
  pk: Scalars['ID']['input']
}

export type QueryFavoriteContentPagesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryFavoriteProductsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryFavoriteVendorsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryGuideArgs = {
  slug: Scalars['String']['input']
}

export type QueryGuidesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryMiniSearchArgs = {
  search: Scalars['String']['input']
}

export type QueryNeedSupportUsersArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryNotFollowedChatsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryOverviewStatisticsArgs = {
  dateRange?: InputMaybe<StatisticsDateRange>
}

export type QueryProductArgs = {
  slug: Scalars['String']['input']
}

export type QueryProductDetailPageArgs = {
  slug: Scalars['String']['input']
}

export type QueryProductsArgs = {
  excludeWebinar?: InputMaybe<Scalars['Boolean']['input']>
  filters?: InputMaybe<ProductFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QuerySearchArgs = {
  filters?: InputMaybe<SearchItemFilter>
  ids?: InputMaybe<Array<Scalars['String']['input']>>
  ignoreMixContent?: InputMaybe<Scalars['Boolean']['input']>
  ignoreMixVendor?: InputMaybe<Scalars['Boolean']['input']>
  isTeamOffer?: InputMaybe<Scalars['Boolean']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
  prioritizedOrder?: InputMaybe<Array<Scalars['String']['input']>>
  search: Scalars['String']['input']
}

export type QuerySearchHistoryArgs = {
  search: Scalars['String']['input']
}

export type QuerySegmentDetailArgs = {
  id: Scalars['ID']['input']
}

export type QuerySegmentsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryServiceArgs = {
  slug: Scalars['String']['input']
}

export type QuerySharedUserInfoArgs = {
  slug: Scalars['String']['input']
}

export type QuerySharedUserInfoListArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryTagPageArgs = {
  slug: Scalars['String']['input']
}

export type QueryThemeArgs = {
  pk: Scalars['ID']['input']
}

export type QueryTimelineArgs = {
  filters?: InputMaybe<TimeLineItemFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryUserInfoArgs = {
  id: Scalars['ID']['input']
}

export type QueryVendorArgs = {
  slug: Scalars['String']['input']
}

export type QueryVendorWithoutUserChatsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QueryVendorsArgs = {
  filters?: InputMaybe<VendorListFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

export type QuestionListResponse = {
  __typename?: 'QuestionListResponse'
  items: Array<QuestionType>
  totalItemsCount: Scalars['Int']['output']
}

/** Question(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image) */
export type QuestionType = {
  __typename?: 'QuestionType'
  child: ScoreQuestionTypeMultipleChoiceQuestionTypeBinaryQuestionTypeSliderQuestionTypeTextQuestionType
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Image>
  themes: Array<DjangoModelType>
  title: Scalars['String']['output']
  type: ScansQuestionTypeEnum
}

/** Question(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image) */
export type QuestionTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type ReadChatInput = {
  chatId: Scalars['ID']['input']
}

export type RequestEmailLoginCodeInput = {
  email: Scalars['Email']['input']
}

export type RequestTextLoginCodeInput = {
  changePhoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  emailCode: Scalars['String']['input']
}

/** Methods can NOT be combined. Falls back to a default (max-800x800) if no option is selected. More info https://docs.wagtail.org/en/stable/topics/images.html#available-resizing-methods */
export type ResizeMethod = {
  fill?: InputMaybe<FillInput>
  height?: InputMaybe<HeightInput>
  max?: InputMaybe<MaxInput>
  min?: InputMaybe<MinInput>
  original?: InputMaybe<OriginalInput>
  scale?: InputMaybe<ScaleInput>
  width?: InputMaybe<WidthInput>
}

/** Resize the image to the percentage specified */
export type ScaleInput = {
  percentage: Scalars['Int']['input']
}

/** Scan(id, locked, locked_at, locked_by, scan_type, status, created_at, updated_at, intro_header, intro_text, outro_header, outro_text) */
export type ScanFilter = {
  AND?: InputMaybe<ScanFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<ScanFilter>
  OR?: InputMaybe<ScanFilter>
  scanType?: InputMaybe<ScansScanScanTypeEnumFilterLookup>
}

/** Scan(id, locked, locked_at, locked_by, scan_type, status, created_at, updated_at, intro_header, intro_text, outro_header, outro_text) */
export type ScanType = {
  __typename?: 'ScanType'
  id: Scalars['ID']['output']
  introHeader: Scalars['String']['output']
  introText: Scalars['HTML']['output']
  outroHeader: Scalars['String']['output']
  outroText: Scalars['HTML']['output']
  questions: QuestionListResponse
  scanType: ScansScanScanTypeEnum
}

/** Scan(id, locked, locked_at, locked_by, scan_type, status, created_at, updated_at, intro_header, intro_text, outro_header, outro_text) */
export type ScanTypeQuestionsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** question | type */
export enum ScansQuestionTypeEnum {
  /** Binaryquestion */
  Binaryquestion = 'binaryquestion',
  /** Multiplechoicequestion */
  Multiplechoicequestion = 'multiplechoicequestion',
  /** Scorequestion */
  Scorequestion = 'scorequestion',
  /** Sliderquestion */
  Sliderquestion = 'sliderquestion',
  /** Textquestion */
  Textquestion = 'textquestion',
}

/** scan | scan type */
export enum ScansScanScanTypeEnum {
  /** Check In */
  CheckIn = 'check_in',
  /** Self Scan */
  SelfScan = 'self_scan',
}

export type ScansScanScanTypeEnumFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<ScansScanScanTypeEnum>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<ScansScanScanTypeEnum>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<ScansScanScanTypeEnum>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<ScansScanScanTypeEnum>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<ScansScanScanTypeEnum>
}

/** user scan | status */
export enum ScansUserScanStatusEnum {
  /** Canceled */
  Canceled = 'canceled',
  /** Finished */
  Finished = 'finished',
  /** In Progress */
  InProgress = 'in_progress',
}

export type ScansUserScanStatusEnumFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<ScansUserScanStatusEnum>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<ScansUserScanStatusEnum>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<ScansUserScanStatusEnum>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<ScansUserScanStatusEnum>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<ScansUserScanStatusEnum>
}

export type ScoreAnswerInput = {
  answer: Scalars['Int']['input']
  questionId: Scalars['ID']['input']
}

/** ScoreQuestion(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image, question_ptr, max_score) */
export type ScoreQuestionType = {
  __typename?: 'ScoreQuestionType'
  id: Scalars['ID']['output']
  maxScore: Scalars['Int']['output']
}

export type ScoreQuestionTypeMultipleChoiceQuestionTypeBinaryQuestionTypeSliderQuestionTypeTextQuestionType =

    | BinaryQuestionType
    | MultipleChoiceQuestionType
    | ScoreQuestionType
    | SliderQuestionType
    | TextQuestionType

export type ScoreType = {
  __typename?: 'ScoreType'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  score: Scalars['String']['output']
}

/** SearchHistory(id, search, user, count, created_at, updated_at) */
export type SearchHistoryType = {
  __typename?: 'SearchHistoryType'
  id: Scalars['ID']['output']
  search: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** SearchItem(id, item_id, text, text_en, price, price_alter_text, location_group, category, is_book, vendor, number_of_sessions, image, use_placeholder_image, order) */
export type SearchItemFilter = {
  AND?: InputMaybe<SearchItemFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<SearchItemFilter>
  OR?: InputMaybe<SearchItemFilter>
  category?: InputMaybe<SearchSearchItemCategoryEnumFilterLookup>
  isBook?: InputMaybe<BoolBaseFilterLookup>
  locationGroup?: InputMaybe<LocationGroupFilter>
  price?: InputMaybe<DecimalComparisonFilterLookup>
  /** A comma-separated list of tags. */
  tags?: InputMaybe<TagFilter>
  themes?: InputMaybe<SearchThemeFilter>
  vendor?: InputMaybe<VendorFilter>
}

export type SearchItemListResponse = {
  __typename?: 'SearchItemListResponse'
  items: Array<SearchItemType>
  totalItemsCount: Scalars['Int']['output']
}

/** SearchItem(id, item_id, text, text_en, price, price_alter_text, location_group, category, is_book, vendor, number_of_sessions, image, use_placeholder_image, order) */
export type SearchItemType = {
  __typename?: 'SearchItemType'
  category: SearchSearchItemCategoryEnum
  id: Scalars['ID']['output']
  image?: Maybe<Image>
  isBook: Scalars['Boolean']['output']
  itemId: Scalars['Int']['output']
  numberOfSessions?: Maybe<Scalars['Int']['output']>
  order?: Maybe<Scalars['Int']['output']>
  price: Scalars['Decimal']['output']
  priceAlterText?: Maybe<Scalars['String']['output']>
  text: Scalars['String']['output']
  textEn: Scalars['String']['output']
  usePlaceholderImage?: Maybe<Scalars['Boolean']['output']>
  vendor?: Maybe<VendorType>
}

/** SearchItem(id, item_id, text, text_en, price, price_alter_text, location_group, category, is_book, vendor, number_of_sessions, image, use_placeholder_image, order) */
export type SearchItemTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** search item | category */
export enum SearchSearchItemCategoryEnum {
  /** Blog */
  Blog = 'BLOG',
  /** Expert */
  Expert = 'EXPERT',
  /** Guide */
  Guide = 'GUIDE',
  /** Product */
  Product = 'PRODUCT',
  /** Service */
  Service = 'SERVICE',
}

export type SearchSearchItemCategoryEnumFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<SearchSearchItemCategoryEnum>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<SearchSearchItemCategoryEnum>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<SearchSearchItemCategoryEnum>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type SearchThemeFilter = {
  AND?: InputMaybe<SearchThemeFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<SearchThemeFilter>
  OR?: InputMaybe<SearchThemeFilter>
  id?: InputMaybe<IdBaseFilterLookup>
  name?: InputMaybe<StrFilterLookup>
  slug?: InputMaybe<StrFilterLookup>
}

/** Segment(id, created_at, updated_at, name, filters) */
export type SegmentInput = {
  contacts: Array<Scalars['ID']['input']>
  filters: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type SegmentListResponse = {
  __typename?: 'SegmentListResponse'
  items: Array<SegmentType>
  totalItemsCount: Scalars['Int']['output']
}

/** Segment(id, created_at, updated_at, name, filters) */
export type SegmentType = {
  __typename?: 'SegmentType'
  contacts: ContactListResponse
  createdAt: Scalars['DateTime']['output']
  filters: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** Segment(id, created_at, updated_at, name, filters) */
export type SegmentTypeContactsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}

export type SendMessageInput = {
  chatId: Scalars['ID']['input']
  content: Scalars['String']['input']
}

/** Service(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, location_group, number_of_sessions) */
export type ServiceFilter = {
  AND?: InputMaybe<ServiceFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<ServiceFilter>
  OR?: InputMaybe<ServiceFilter>
  id?: InputMaybe<IdBaseFilterLookup>
  locationGroup?: InputMaybe<LocationGroupFilter>
  name?: InputMaybe<StrFilterLookup>
  vendor?: InputMaybe<VendorFilter>
}

/** Service(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, location_group, number_of_sessions) */
export type ServiceType = {
  __typename?: 'ServiceType'
  child?: Maybe<ItemTypeServiceType>
  /** Appears on the cards or on product/service detail page when no rich description is provided. */
  description: Scalars['String']['output']
  descriptionImages?: Maybe<Array<Image>>
  id: Scalars['ID']['output']
  /** Used when template is SINGLE_IMAGE, SINGLE_BIG_IMAGE or BOOK */
  image?: Maybe<Image>
  images?: Maybe<Array<ProductImagesType>>
  languages: Array<LanguageType>
  locationGroup?: Maybe<LocationGroupType>
  name: Scalars['String']['output']
  numberOfSessions?: Maybe<Scalars['Int']['output']>
  price: Scalars['Decimal']['output']
  priceAlterText?: Maybe<ProductsProductPriceAlterTextEnum>
  productPtr: DjangoModelType
  /** Rich text with more details, images, videos... */
  richDescription: Scalars['HTML']['output']
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  template: ProductsProductTemplateEnum
  themes: Array<ThemeType>
  usePlaceholderImage: Scalars['Boolean']['output']
  vendor: VendorType
}

/** Service(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, location_group, number_of_sessions) */
export type ServiceTypeDescriptionImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Service(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, location_group, number_of_sessions) */
export type ServiceTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** SharedUserInfo(id, user, status, message, slug, already_accepted) */
export type ShareInfoApproval = {
  slug: Scalars['String']['input']
  status: StatusChoices
}

export type SharedUserInfoListResponse = {
  __typename?: 'SharedUserInfoListResponse'
  items: Array<SharedUserInfoType>
  totalItemsCount: Scalars['Int']['output']
}

/** SharedUserInfo(id, user, status, message, slug, already_accepted) */
export type SharedUserInfoType = {
  __typename?: 'SharedUserInfoType'
  message: Scalars['String']['output']
  slug: Scalars['String']['output']
  status: UsersSharedUserInfoStatusEnum
  user: UserType
  vendors: Array<VendorType>
}

/** The self_scan status options */
export enum ShowScanOptions {
  CheckIn = 'CHECK_IN',
  Nothing = 'NOTHING',
  SelfScan = 'SELF_SCAN',
}

export type SliderAnswerInput = {
  answer: Scalars['Int']['input']
  questionId: Scalars['ID']['input']
}

/** SliderQuestion(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image, question_ptr, max_score, energy_type) */
export type SliderQuestionType = {
  __typename?: 'SliderQuestionType'
  id: Scalars['ID']['output']
  maxScore: Scalars['Int']['output']
}

export type StaffBookingApprovalPayload = BookingType | OperationInfo

export type StartScanInput = {
  scanId: Scalars['ID']['input']
}

/** The self_scan status options */
export enum StatisticsDateRange {
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
}

export enum StatusChoices {
  Approved = 'APPROVED',
  New = 'NEW',
  Pending = 'PENDING',
  Refused = 'REFUSED',
}

export type StrBaseFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['String']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['String']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type StrComparisonFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['String']['input']>
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['String']['input']>
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['String']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['String']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['String']['input']>
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['String']['input']>
  /** Inclusive range test (between) */
  range?: InputMaybe<StrRangeLookup>
}

export type StrFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<Scalars['String']['input']>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<Scalars['String']['input']>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['String']['input']>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<Scalars['String']['input']>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<Scalars['String']['input']>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<Scalars['String']['input']>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<Scalars['String']['input']>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<Scalars['String']['input']>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['String']['input']>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<Scalars['String']['input']>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type StrRangeLookup = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

/** Study(id, sort_order, name, vendor) */
export type StudyType = {
  __typename?: 'StudyType'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SuggestBookingDatePayload = BookingType | OperationInfo

/** Booking(id, created_at, updated_at, price, status, employee, product, date, employee_comment, vendor_billing_status, company_billing_status) */
export type SuggestDateInput = {
  /** For services: current proposal for when it should take place */
  date?: InputMaybe<Scalars['Date']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Tag(id, name, slug) */
export type TagFilter = {
  AND?: InputMaybe<TagFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<TagFilter>
  OR?: InputMaybe<TagFilter>
  id?: InputMaybe<IdBaseFilterLookup>
  name?: InputMaybe<StrFilterLookup>
  slug?: InputMaybe<StrFilterLookup>
}

/** TagHighlight(id, sort_order, tag_page, product, vendor, content_page) */
export type TagHighlightType = {
  __typename?: 'TagHighlightType'
  highlightedItem: ProductTypeVendorTypeContentPageType
}

/** TagPage(id, description_header, description, icon, tag) */
export type TagPageType = {
  __typename?: 'TagPageType'
  contentPages: ContentPageListResponse
  description: Scalars['String']['output']
  descriptionHeader: Scalars['String']['output']
  highlights: Array<TagHighlightType>
  icon?: Maybe<Image>
  id: Scalars['ID']['output']
  products: ProductListResponse
  tag: TagType
  vendors: VendorListResponse
}

/** TagPage(id, description_header, description, icon, tag) */
export type TagPageTypeContentPagesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** TagPage(id, description_header, description, icon, tag) */
export type TagPageTypeHighlightsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** TagPage(id, description_header, description, icon, tag) */
export type TagPageTypeIconArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** TagPage(id, description_header, description, icon, tag) */
export type TagPageTypeProductsArgs = {
  filters?: InputMaybe<ProductFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** TagPage(id, description_header, description, icon, tag) */
export type TagPageTypeVendorsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** Tag(id, name, slug) */
export type TagType = {
  __typename?: 'TagType'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type Terms = {
  __typename?: 'Terms'
  accepted: Scalars['Boolean']['output']
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  version: Scalars['Int']['output']
}

export type TextAnswerInput = {
  answer: Scalars['String']['input']
  questionId: Scalars['ID']['input']
}

/** TextQuestion(id, locked, locked_at, locked_by, sort_order, scan, title, description, type, image, question_ptr) */
export type TextQuestionType = {
  __typename?: 'TextQuestionType'
  id: Scalars['ID']['output']
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ThemeFilter = {
  AND?: InputMaybe<ThemeFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<ThemeFilter>
  OR?: InputMaybe<ThemeFilter>
  slug?: InputMaybe<StrBaseFilterLookup>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ThemeType = {
  __typename?: 'ThemeType'
  color?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  highlights: Array<HighlightType>
  icon?: Maybe<Image>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  order: Scalars['Int']['output']
  slug: Scalars['String']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ThemeTypeHighlightsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** Theme(id, name, slug, description_header, description, icon, color, order) */
export type ThemeTypeIconArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** TimelineItem(id, employee, date, item_type, title, image) */
export type TimeLineItem = {
  __typename?: 'TimeLineItem'
  date: Scalars['Date']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Image>
  itemType: OrganizationsTimelineItemItemTypeEnum
  title?: Maybe<Scalars['String']['output']>
}

/** TimelineItem(id, employee, date, item_type, title, image) */
export type TimeLineItemImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** TimelineItem(id, employee, date, item_type, title, image) */
export type TimeLineItemFilter = {
  AND?: InputMaybe<TimeLineItemFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<TimeLineItemFilter>
  OR?: InputMaybe<TimeLineItemFilter>
  date?: InputMaybe<DateDateFilterLookup>
}

export type TimeLineItemListResponse = {
  __typename?: 'TimeLineItemListResponse'
  items: Array<TimeLineItem>
  totalItemsCount: Scalars['Int']['output']
}

export type UnreadChatType = {
  __typename?: 'UnreadChatType'
  fromCustomerSupport: Scalars['Boolean']['output']
  normal: Scalars['Boolean']['output']
}

/** Booking(id, created_at, updated_at, price, status, employee, product, date, employee_comment, vendor_billing_status, company_billing_status) */
export type UpdateBillingStatusInput = {
  companyBillingStatus?: InputMaybe<BillingStatusChoices>
  id?: InputMaybe<Scalars['ID']['input']>
  vendorBillingStatus?: InputMaybe<BillingStatusChoices>
}

export type UpdateBillingStatusPayload = BookingType | OperationInfo

/** Chat(id, created_at, participant_1, participant_2, last_sent, archived, flagged) */
export type UpdateChatInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  flagged?: InputMaybe<Scalars['Boolean']['input']>
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type UpdateLanguageInput = {
  language?: InputMaybe<UsersUserLanguageEnum>
}

export type UpdateLanguagePayload = OperationInfo | UserType

/** Message(id, created_at, read_at, automated_message, sent_email_at, user, sender, receiver, chat, content, type, archived, marked_unread, flagged) */
export type UpdateMessageInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  flagged?: InputMaybe<Scalars['Boolean']['input']>
  markedUnread?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateProfilePayload = OperationInfo | UserType

export type UpdateThemeInput = {
  message: Scalars['String']['input']
  themeToAdd: Scalars['ID']['input']
  themeToRemove: Scalars['ID']['input']
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type UserFilter = {
  AND?: InputMaybe<UserFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<UserFilter>
  OR?: InputMaybe<UserFilter>
  companyMemberships?: InputMaybe<CompanyMemberFilter>
  completedSelfscan?: InputMaybe<Scalars['Boolean']['input']>
  dateOfBirth?: InputMaybe<DateDateFilterLookup>
  email?: InputMaybe<StrFilterLookup>
  familyStatus?: InputMaybe<UsersUserFamilyStatusEnumFilterLookup>
  firstName?: InputMaybe<StrFilterLookup>
  gender?: InputMaybe<UsersUserGenderEnumFilterLookup>
  language?: InputMaybe<UsersUserLanguageEnumFilterLookup>
  lastCheckin?: InputMaybe<StrComparisonFilterLookup>
  lastContact?: InputMaybe<StrComparisonFilterLookup>
  lastLogin?: InputMaybe<StrComparisonFilterLookup>
  lastName?: InputMaybe<StrFilterLookup>
  lastSms?: InputMaybe<StrComparisonFilterLookup>
  phoneNumber?: InputMaybe<StrBaseFilterLookup>
  userScans?: InputMaybe<UserScanFilter>
  userThemes?: InputMaybe<UserThemesFilter>
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type UserInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  familyStatus?: InputMaybe<FamilyStatusChoices>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<GenderChoices>
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type UserListResponse = {
  __typename?: 'UserListResponse'
  items: Array<UserType>
  totalItemsCount: Scalars['Int']['output']
}

/** UserScan(id, created_at, updated_at, status, user, scan, need_support, supported) */
export type UserScanFilter = {
  AND?: InputMaybe<UserScanFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<UserScanFilter>
  OR?: InputMaybe<UserScanFilter>
  scan?: InputMaybe<ScanFilter>
  status?: InputMaybe<ScansUserScanStatusEnumFilterLookup>
  updatedAt?: InputMaybe<DatetimeDatetimeFilterLookup>
}

/** UserTheme(id, created_at, updated_at, user, theme, is_favorite, was_favorite, scan_type, score) */
export type UserThemeFilter = {
  AND?: InputMaybe<UserThemeFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<UserThemeFilter>
  OR?: InputMaybe<UserThemeFilter>
  /** Did the user pick this theme as a favorite? */
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>
  /** Between 0 and 1, based on the scans the user has done */
  score?: InputMaybe<Scalars['Decimal']['input']>
  /** Did the user previously have this as a favorite? */
  wasFavorite?: InputMaybe<Scalars['Boolean']['input']>
}

/** UserTheme(id, created_at, updated_at, user, theme, is_favorite, was_favorite, scan_type, score) */
export type UserThemeType = {
  __typename?: 'UserThemeType'
  answers: Array<ConvertedAnswer>
  id: Scalars['ID']['output']
  /** Did the user pick this theme as a favorite? */
  isFavorite: Scalars['Boolean']['output']
  scanType: UsersUserThemeScanTypeEnum
  /** Between 0 and 1, based on the scans the user has done */
  score?: Maybe<Scalars['Decimal']['output']>
  theme: ThemeType
  user: UserType
  /** Did the user previously have this as a favorite? */
  wasFavorite: Scalars['Boolean']['output']
}

/** UserTheme(id, created_at, updated_at, user, theme, is_favorite, was_favorite, scan_type, score) */
export type UserThemesFilter = {
  AND?: InputMaybe<UserThemesFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<UserThemesFilter>
  OR?: InputMaybe<UserThemesFilter>
  /** Did the user pick this theme as a favorite? */
  isFavorite?: InputMaybe<BoolBaseFilterLookup>
  /** Between 0 and 1, based on the scans the user has done */
  score?: InputMaybe<DecimalComparisonFilterLookup>
  theme?: InputMaybe<ThemeFilter>
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type UserType = {
  __typename?: 'UserType'
  companyMember?: Maybe<CompanyMemberType>
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  email: Scalars['String']['output']
  familyStatus: UsersUserFamilyStatusEnum
  firstName: Scalars['String']['output']
  gender: UsersUserGenderEnum
  id: Scalars['ID']['output']
  isCustomerSupport: Scalars['Boolean']['output']
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output']
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output']
  language: UsersUserLanguageEnum
  lastLogin?: Maybe<Scalars['DateTime']['output']>
  lastName: Scalars['String']['output']
  nonThemeAnswers?: Maybe<Array<ConvertedAnswer>>
  phoneNumber?: Maybe<Scalars['String']['output']>
  userThemes: Array<UserThemeType>
  vendorMember?: Maybe<VendorMemberType>
}

/** User(id, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone_number, date_of_birth, gender, family_status, email_notifications_enabled, language, sent_reminder_at, sent_to_sendgrid, is_customer_support) */
export type UserTypeUserThemesArgs = {
  filters?: InputMaybe<UserThemeFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}

/** shared user info | status */
export enum UsersSharedUserInfoStatusEnum {
  /** Approved */
  Approved = 'APPROVED',
  /** New */
  New = 'NEW',
  /** Pending */
  Pending = 'PENDING',
  /** Refused */
  Refused = 'REFUSED',
}

/** user | family status */
export enum UsersUserFamilyStatusEnum {
  /** In Relationship No Children */
  InRelationshipNoChildren = 'IN_RELATIONSHIP_NO_CHILDREN',
  /** In Relationship With Children */
  InRelationshipWithChildren = 'IN_RELATIONSHIP_WITH_CHILDREN',
  /** Single */
  Single = 'SINGLE',
  /** Single Parent */
  SingleParent = 'SINGLE_PARENT',
}

export type UsersUserFamilyStatusEnumFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<UsersUserFamilyStatusEnum>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<UsersUserFamilyStatusEnum>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<UsersUserFamilyStatusEnum>
}

/** user | gender */
export enum UsersUserGenderEnum {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Not Say */
  NotSay = 'NOT_SAY',
  /** Other */
  Other = 'OTHER',
}

export type UsersUserGenderEnumFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<UsersUserGenderEnum>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<UsersUserGenderEnum>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<UsersUserGenderEnum>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<UsersUserGenderEnum>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<UsersUserGenderEnum>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<UsersUserGenderEnum>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<UsersUserGenderEnum>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<UsersUserGenderEnum>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<UsersUserGenderEnum>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<UsersUserGenderEnum>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<UsersUserGenderEnum>
}

/** user | language */
export enum UsersUserLanguageEnum {
  /** English */
  En = 'en',
  /** Dutch */
  Nl = 'nl',
}

export type UsersUserLanguageEnumFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<UsersUserLanguageEnum>
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<UsersUserLanguageEnum>
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<UsersUserLanguageEnum>
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<UsersUserLanguageEnum>
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<UsersUserLanguageEnum>
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<UsersUserLanguageEnum>
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<UsersUserLanguageEnum>
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<UsersUserLanguageEnum>
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<UsersUserLanguageEnum>>
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<UsersUserLanguageEnum>
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<UsersUserLanguageEnum>
}

/** user theme | scan type */
export enum UsersUserThemeScanTypeEnum {
  /** Check In */
  CheckIn = 'check_in',
  /** Self Scan */
  SelfScan = 'self_scan',
}

export type VendorBookingApprovalPayload = BookingType | OperationInfo

/** Vendor(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, created_at, description, description_2, description_3, is_exclusive, location_group, image, small_image) */
export type VendorFilter = {
  AND?: InputMaybe<VendorFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<VendorFilter>
  OR?: InputMaybe<VendorFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  locationGroup?: InputMaybe<LocationGroupFilter>
  province?: InputMaybe<OrganizationsVendorProvinceEnum>
  /** A comma-separated list of tags. */
  tags?: InputMaybe<TagFilter>
}

/** Vendor(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, created_at, description, description_2, description_3, is_exclusive, location_group, image, small_image) */
export type VendorListFilter = {
  AND?: InputMaybe<VendorListFilter>
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>
  NOT?: InputMaybe<VendorListFilter>
  OR?: InputMaybe<VendorListFilter>
  locationGroup?: InputMaybe<LocationGroupFilter>
  products?: InputMaybe<ProductFilter>
  /** This is the unique identifier for the organization, used to create the URL. */
  slug?: InputMaybe<StrFilterLookup>
  /** A comma-separated list of tags. */
  tags?: InputMaybe<TagFilter>
  themes?: InputMaybe<SearchThemeFilter>
}

export type VendorListResponse = {
  __typename?: 'VendorListResponse'
  items: Array<VendorType>
  totalItemsCount: Scalars['Int']['output']
}

/** VendorMember(id, vendor, user, invited_at, access_expires_at) */
export type VendorMemberType = {
  __typename?: 'VendorMemberType'
  id: Scalars['ID']['output']
  vendor: VendorType
}

export type VendorStatisticsType = {
  __typename?: 'VendorStatisticsType'
  bookedServices: Scalars['Int']['output']
  receiveBudget: Scalars['Float']['output']
}

/** Vendor(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, created_at, description, description_2, description_3, is_exclusive, location_group, image, small_image) */
export type VendorType = {
  __typename?: 'VendorType'
  addressLine1: Scalars['String']['output']
  addressLine2: Scalars['String']['output']
  city: Scalars['String']['output']
  cocNumber: Scalars['String']['output']
  /** Appears on the cards */
  description: Scalars['String']['output']
  /** 'Meer over deze expert' on the Vendor page */
  description2: Scalars['String']['output']
  /** 'Hoe is het om met deze expert te werken' on the Vendor page */
  description3: Scalars['String']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Image>
  languages: Array<LanguageType>
  locationGroup?: Maybe<LocationGroupType>
  name: Scalars['String']['output']
  postcode: Scalars['String']['output']
  products: Array<ProductType>
  province: OrganizationsVendorProvinceEnum
  /** This is the unique identifier for the organization, used to create the URL. */
  slug: Scalars['String']['output']
  smallImage?: Maybe<Image>
  studies: Array<StudyType>
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  themes: Array<ThemeType>
  website: Scalars['String']['output']
}

/** Vendor(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, created_at, description, description_2, description_3, is_exclusive, location_group, image, small_image) */
export type VendorTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Vendor(id, name, slug, address_line_1, address_line_2, postcode, city, province, coc_number, website, created_at, description, description_2, description_3, is_exclusive, location_group, image, small_image) */
export type VendorTypeSmallImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

export type WebinarListResponse = {
  __typename?: 'WebinarListResponse'
  items: Array<WebinarType>
  totalItemsCount: Scalars['Int']['output']
}

/** Webinar(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, start_at, end_at, active, external_link) */
export type WebinarType = {
  __typename?: 'WebinarType'
  child?: Maybe<ItemTypeServiceType>
  /** Appears on the cards or on product/service detail page when no rich description is provided. */
  description: Scalars['String']['output']
  descriptionImages?: Maybe<Array<Image>>
  endAt: Scalars['DateTime']['output']
  externalLink?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** Used when template is SINGLE_IMAGE, SINGLE_BIG_IMAGE or BOOK */
  image?: Maybe<Image>
  images?: Maybe<Array<ProductImagesType>>
  name: Scalars['String']['output']
  price: Scalars['Decimal']['output']
  priceAlterText?: Maybe<ProductsProductPriceAlterTextEnum>
  productPtr: DjangoModelType
  registered: Scalars['Boolean']['output']
  /** Rich text with more details, images, videos... */
  richDescription: Scalars['HTML']['output']
  slug: Scalars['String']['output']
  startAt: Scalars['DateTime']['output']
  /** A comma-separated list of tags. */
  tags: Array<TagType>
  template: ProductsProductTemplateEnum
  themes: Array<ThemeType>
  usePlaceholderImage: Scalars['Boolean']['output']
  vendor: VendorType
}

/** Webinar(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, start_at, end_at, active, external_link) */
export type WebinarTypeDescriptionImagesArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Webinar(id, created_at, name, slug, description, rich_description, template, image, vendor, price, price_alter_text, order, use_placeholder_image, product_ptr, start_at, end_at, active, external_link) */
export type WebinarTypeImageArgs = {
  resize?: InputMaybe<ResizeMethod>
}

/** Reduces the width of the image to the dimension specified */
export type WidthInput = {
  value: Scalars['Int']['input']
}

export type LocationsQueryVariables = Exact<{ [key: string]: never }>

export type LocationsQuery = {
  __typename?: 'Query'
  locations: Array<{
    __typename?: 'LocationType'
    id: string
    name: string
    category?: LocationsLocationCategoryEnum | null
  }>
}

export type UpdateLanguageMutationVariables = Exact<{
  language: UsersUserLanguageEnum
}>

export type UpdateLanguageMutation = {
  __typename?: 'Mutation'
  updateLanguage:
    | { __typename?: 'OperationInfo' }
    | { __typename?: 'UserType'; language: UsersUserLanguageEnum }
}

export type ThemesQueryQueryVariables = Exact<{ [key: string]: never }>

export type ThemesQueryQuery = {
  __typename?: 'Query'
  themes: Array<{
    __typename?: 'ThemeType'
    id: string
    name: string
    slug: string
    icon?: { __typename?: 'Image'; url: string } | null
    highlights: Array<{
      __typename?: 'HighlightType'
      highlightedItem:
        | {
            __typename: 'ContentPageType'
            id: string
            child?:
              | { __typename: 'BlogType'; id: string }
              | { __typename: 'GuideType'; id: string }
              | null
          }
        | {
            __typename: 'ProductType'
            id: string
            child?:
              | { __typename: 'ItemType'; id: string }
              | { __typename: 'ServiceType'; id: string }
              | null
          }
        | { __typename: 'VendorType'; id: string }
    }>
  }>
}

export type TagPagesQueryVariables = Exact<{ [key: string]: never }>

export type TagPagesQuery = {
  __typename?: 'Query'
  tagPages: Array<{
    __typename?: 'TagPageType'
    id: string
    icon?: { __typename?: 'Image'; url: string } | null
    tag: { __typename?: 'TagType'; slug: string; name: string }
  }>
}

export type CreateFavoriteMutationMutationVariables = Exact<{
  input: FavoriteInput
}>

export type CreateFavoriteMutationMutation = { __typename?: 'Mutation'; createFavorite: any }

export type DeleteFavoriteMutationMutationVariables = Exact<{
  input: FavoriteInput
}>

export type DeleteFavoriteMutationMutation = { __typename?: 'Mutation'; deleteFavorite: any }

export type FavoritesQueryVariables = Exact<{ [key: string]: never }>

export type FavoritesQuery = {
  __typename?: 'Query'
  favorites: Array<{
    __typename?: 'FavoriteItem'
    itemId: string
    type: FavoritesFavoriteItemTypeEnum
  }>
}

export type BlogQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type BlogQuery = {
  __typename?: 'Query'
  blog: {
    __typename?: 'BlogType'
    id: string
    title: string
    slug: string
    intro: string
    body: any
    author?: string | null
    mainImage?: { __typename?: 'Image'; id: string; url: string } | null
    vendor?: {
      __typename?: 'VendorType'
      name: string
      slug: string
      smallImage?: {
        __typename?: 'Image'
        alt: string
        height: number
        id: string
        url: string
        width: number
      } | null
    } | null
    images?: Array<{ __typename?: 'Image'; baseImageId: string; url: string }> | null
    themes: Array<{
      __typename: 'ThemeType'
      id: string
      name: string
      slug: string
      icon?: { __typename?: 'Image'; url: string } | null
    }>
    tags: Array<{ __typename: 'TagType'; id: string; name: string; slug: string }>
  }
}

export type BlogsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type BlogsQuery = {
  __typename?: 'Query'
  blogs: {
    __typename?: 'BlogListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'BlogType'
      id: string
      title: string
      slug: string
      intro: string
      mainImage?: { __typename?: 'Image'; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      } | null
      images?: Array<{ __typename?: 'Image'; url: string }> | null
      child?: { __typename: 'BlogType' } | { __typename: 'GuideType' } | null
    }>
  }
}

export type CreateBookingMutationVariables = Exact<{
  input: CreateBookingInput
}>

export type CreateBookingMutation = {
  __typename?: 'Mutation'
  createBooking:
    | { __typename?: 'BookingType'; status: BookingsBookingStatusEnum }
    | { __typename?: 'OperationInfo' }
}

export type RequestBudgetMutationVariables = Exact<{
  input: BudgetRequestInput
}>

export type RequestBudgetMutation = { __typename?: 'Mutation'; budgetRequest: any }

export type AcceptPurchaseAgreementMutationVariables = Exact<{
  input: AcceptTermsInput
}>

export type AcceptPurchaseAgreementMutation = {
  __typename?: 'Mutation'
  acceptPurchaseAgreement: {
    __typename?: 'Terms'
    createdAt: any
    version: number
    content: string
    accepted: boolean
  }
}

export type PurchaseAgreementQueryVariables = Exact<{ [key: string]: never }>

export type PurchaseAgreementQuery = {
  __typename?: 'Query'
  purchaseAgreement: {
    __typename?: 'Terms'
    accepted: boolean
    content: string
    createdAt: any
    version: number
  }
}

export type CreateSegmentMutationVariables = Exact<{
  input: SegmentInput
}>

export type CreateSegmentMutation = {
  __typename?: 'Mutation'
  createSegment: { __typename?: 'SegmentType'; id: string }
}

export type UpdateSegmentMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: SegmentInput
}>

export type UpdateSegmentMutation = {
  __typename?: 'Mutation'
  updateSegment: { __typename?: 'SegmentType'; id: string }
}

export type CreateCampaignMessageTemplateMutationVariables = Exact<{
  input: CampaignMessageTemplateInput
}>

export type CreateCampaignMessageTemplateMutation = {
  __typename?: 'Mutation'
  createCampaignMessageTemplate: {
    __typename?: 'CampaignMessageTemplateType'
    content: string
    id: string
    name: string
  }
}

export type UpdateCampaignMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CampaignMessageTemplateInput
}>

export type UpdateCampaignMessageTemplateMutation = {
  __typename?: 'Mutation'
  updateCampaignMessageTemplate: {
    __typename?: 'CampaignMessageTemplateType'
    content: string
    id: string
    name: string
  }
}

export type CreateCampaignMutationVariables = Exact<{
  input: CampaignInput
}>

export type CreateCampaignMutation = {
  __typename?: 'Mutation'
  createCampaign: {
    __typename?: 'CampaignType'
    message: string
    name: string
    id: string
    sentAt?: any | null
    scheduledAt?: any | null
    segment: {
      __typename?: 'SegmentType'
      name: string
      filters: string
      contacts: { __typename?: 'ContactListResponse'; totalItemsCount: number }
    }
  }
}

export type UpdateCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CampaignInput
}>

export type UpdateCampaignMutation = {
  __typename?: 'Mutation'
  updateCampaign: {
    __typename?: 'CampaignType'
    message: string
    name: string
    id: string
    sentAt?: any | null
    scheduledAt?: any | null
    segment: {
      __typename?: 'SegmentType'
      name: string
      filters: string
      contacts: { __typename?: 'ContactListResponse'; totalItemsCount: number }
    }
  }
}

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCampaignMutation = { __typename?: 'Mutation'; deleteCampaign: boolean }

export type CampaignUsersQueryVariables = Exact<{
  filters?: InputMaybe<UserFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type CampaignUsersQuery = {
  __typename?: 'Query'
  campaignUsers: {
    __typename?: 'UserListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'UserType'
      firstName: string
      lastName: string
      email: string
      phoneNumber?: string | null
      id: string
      lastLogin?: any | null
      companyMember?: {
        __typename?: 'CompanyMemberType'
        budget?: any | null
        company: { __typename?: 'CompanyType'; name: string }
      } | null
      userThemes: Array<{
        __typename?: 'UserThemeType'
        theme: { __typename?: 'ThemeType'; name: string }
      }>
    }>
  }
}

export type SegmentsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type SegmentsQuery = {
  __typename?: 'Query'
  segments: {
    __typename?: 'SegmentListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'SegmentType'
      name: string
      filters: string
      updatedAt: any
      id: string
      contacts: { __typename?: 'ContactListResponse'; totalItemsCount: number }
    }>
  }
}

export type SegmentDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}>

export type SegmentDetailQuery = {
  __typename?: 'Query'
  segmentDetail: {
    __typename?: 'SegmentType'
    name: string
    filters: string
    id: string
    updatedAt: any
    contacts: {
      __typename?: 'ContactListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'ContactType'
        firstName: string
        lastName: string
        email: string
        id: string
        lastLogin?: any | null
        phoneNumber?: string | null
        companyMember?: {
          __typename?: 'CompanyMemberType'
          budget?: any | null
          company: { __typename?: 'CompanyType'; name: string }
        } | null
        userThemes: Array<{
          __typename?: 'UserThemeType'
          theme: { __typename?: 'ThemeType'; name: string }
        }>
      }>
    }
  }
}

export type MessageTemplatesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type MessageTemplatesQuery = {
  __typename?: 'Query'
  campaignMessageTemplates: {
    __typename?: 'CampaignMessageTemplateListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'CampaignMessageTemplateType'
      id: string
      name: string
      content: string
    }>
  }
}

export type CampaignsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type CampaignsQuery = {
  __typename?: 'Query'
  campaigns: {
    __typename?: 'CampaignListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'CampaignType'
      id: string
      message: string
      name: string
      sentAt?: any | null
      scheduledAt?: any | null
      segment: {
        __typename?: 'SegmentType'
        id: string
        name: string
        contacts: { __typename?: 'ContactListResponse'; totalItemsCount: number }
      }
    }>
  }
}

export type BudgetCompanyApprovalMutationVariables = Exact<{
  input: BudgetCompanyApprovalInput
}>

export type BudgetCompanyApprovalMutation = { __typename?: 'Mutation'; budgetCompanyApproval: any }

export type CreateCompanyEmployeeMutationVariables = Exact<{
  input: CreateUserInput
  budget?: InputMaybe<Scalars['Float']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
}>

export type CreateCompanyEmployeeMutation = { __typename?: 'Mutation'; createCompanyEmployee: any }

export type UpdateCompanyEmployeeMutationVariables = Exact<{
  input: CreateUserInput
  companyId?: InputMaybe<Scalars['ID']['input']>
  budget?: InputMaybe<Scalars['Float']['input']>
}>

export type UpdateCompanyEmployeeMutation = { __typename?: 'Mutation'; updateCompanyEmployee: any }

export type DeleteCompanyEmployeeMutationVariables = Exact<{
  id: Scalars['ID']['input']
  companyId?: InputMaybe<Scalars['ID']['input']>
}>

export type DeleteCompanyEmployeeMutation = { __typename?: 'Mutation'; deleteCompanyEmployee: any }

export type FreeTierBudgetRequestMutationVariables = Exact<{
  input: FreeTierBudgetRequestInput
}>

export type FreeTierBudgetRequestMutation = { __typename?: 'Mutation'; freeTierBudgetRequest: any }

export type SendInviteMutationVariables = Exact<{
  employeeId: Scalars['ID']['input']
}>

export type SendInviteMutation = { __typename?: 'Mutation'; sendInvite: any }

export type CompanyStatisticsQueryVariables = Exact<{ [key: string]: never }>

export type CompanyStatisticsQuery = {
  __typename?: 'Query'
  companyStatistics: {
    __typename?: 'CompanyStatisticsType'
    privateEnergy: number
    seatsInUse: number
    totalBookedServices: number
    totalPendingBookings: number
    totalSeats: number
    workEnergy: number
    totalUsersFinishedScan: number
    totalFinishedSelfscan: number
    totalFinishedCheckin: number
    themes: Array<{
      __typename?: 'CompanyThemeType'
      score: number
      theme: {
        __typename?: 'ThemeType'
        id: string
        slug: string
        name: string
        color?: string | null
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
    mostFavoriteThemes: Array<{
      __typename?: 'CompanyThemeType'
      score: number
      theme: {
        __typename?: 'ThemeType'
        id: string
        slug: string
        name: string
        color?: string | null
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
  }
}

export type FilteredCompanyStatisticsQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>
  dateRange?: InputMaybe<StatisticsDateRange>
}>

export type FilteredCompanyStatisticsQuery = {
  __typename?: 'Query'
  companyStatistics: { __typename?: 'CompanyStatisticsType'; filteredBookedServices: number }
}

export type BudgetRequestsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}>

export type BudgetRequestsQuery = {
  __typename?: 'Query'
  budgetRequests: {
    __typename?: 'BudgetRequestListResponse'
    totalItemsCount: number
    outstandingItemsCount: number
    items: Array<{
      __typename?: 'BudgetRequest'
      id: string
      amount?: any | null
      createdAt: any
      updatedAt: any
      approvedByCompany?: boolean | null
      message: string
      product?: {
        __typename?: 'ProductType'
        name: string
        slug: string
        price: any
        child?:
          | { __typename: 'ItemType'; id: string }
          | { __typename: 'ServiceType'; numberOfSessions?: number | null }
          | null
        vendor: {
          __typename?: 'VendorType'
          name: string
          slug: string
          smallImage?: { __typename?: 'Image'; url: string } | null
        }
      } | null
      employee: {
        __typename?: 'Employee'
        budget?: any | null
        user: { __typename?: 'UserType'; lastName: string; firstName: string }
      }
    }>
  }
}

export type CompanyMembersQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  selfscan?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type CompanyMembersQuery = {
  __typename?: 'Query'
  companyMembers: {
    __typename?: 'EmployeeListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'Employee'
      id: string
      inactive: boolean
      budget?: any | null
      user: {
        __typename?: 'UserType'
        id: string
        email: string
        lastName: string
        firstName: string
        phoneNumber?: string | null
        lastLogin?: any | null
        userThemes: Array<{ __typename?: 'UserThemeType'; id: string }>
      }
    }>
  }
}

export type CompanyQueryVariables = Exact<{ [key: string]: never }>

export type CompanyQuery = {
  __typename?: 'Query'
  company: { __typename?: 'CompanyType'; totalSeats: number; seatsInUse: number }
}

export type MostChosenSearchesQueryVariables = Exact<{ [key: string]: never }>

export type MostChosenSearchesQuery = {
  __typename?: 'Query'
  mostChosenSearches: Array<{
    __typename?: 'MostChosenType'
    id: string
    order: number
    text: string
    linkedItems: Array<string>
  }>
}

export type MarkSupportedUserMutationVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type MarkSupportedUserMutation = { __typename?: 'Mutation'; markSupportedUser: boolean }

export type BulkUpdateEmployeeBudgetMutationVariables = Exact<{
  slug: Scalars['String']['input']
  budget: Scalars['Float']['input']
}>

export type BulkUpdateEmployeeBudgetMutation = {
  __typename?: 'Mutation'
  bulkUpdateEmployeeBudget: any
}

export type SynchronizeSendgridMutationVariables = Exact<{ [key: string]: never }>

export type SynchronizeSendgridMutation = {
  __typename?: 'Mutation'
  synchronizeUsersToSendgrid: number
  createSendgridSegments: boolean
}

export type CompaniesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type CompaniesQuery = {
  __typename?: 'Query'
  companies: {
    __typename?: 'CompanyListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'CompanyType'
      id: string
      slug: string
      name: string
      seatsInUse: number
      totalSeats: number
    }>
  }
}

export type CompanyDetailQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type CompanyDetailQuery = {
  __typename?: 'Query'
  companyStatistics: {
    __typename?: 'CompanyStatisticsType'
    seatsInUse: number
    totalSeats: number
    totalBookedServices: number
    totalPendingBookings: number
    totalUsersFinishedScan: number
    totalPendingBudget: number
    totalSpendBudget: number
    totalAvailableBudget: number
    privateEnergy: number
    totalFinishedCheckin: number
    totalFinishedSelfscan: number
    workEnergy: number
    company: {
      __typename?: 'CompanyType'
      id: string
      name: string
      budgetPerEmployee: any
      registeredMembers: number
    }
    mostFavoriteThemes: Array<{
      __typename?: 'CompanyThemeType'
      score: number
      theme: {
        __typename?: 'ThemeType'
        id: string
        slug: string
        name: string
        color?: string | null
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
    themes: Array<{
      __typename?: 'CompanyThemeType'
      score: number
      theme: {
        __typename?: 'ThemeType'
        id: string
        slug: string
        name: string
        color?: string | null
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
  }
}

export type UserInfoQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserInfoQuery = {
  __typename?: 'Query'
  userInfo: {
    __typename?: 'UserType'
    firstName: string
    lastName: string
    email: string
    companyMember?: {
      __typename?: 'CompanyMemberType'
      workEnergy?: any | null
      privateEnergy?: any | null
      company: { __typename?: 'CompanyType'; name: string }
    } | null
    userThemes: Array<
      { __typename?: 'UserThemeType' } & {
        ' $fragmentRefs'?: { ScoredThemeFragmentFragment: ScoredThemeFragmentFragment }
      }
    >
    nonThemeAnswers?: Array<{ __typename?: 'ConvertedAnswer'; text: string; answer: string }> | null
  }
}

export type NeedSupportUsersQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type NeedSupportUsersQuery = {
  __typename?: 'Query'
  needSupportUsers: {
    __typename?: 'UserListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'UserType'
      id: string
      firstName: string
      lastName: string
      email: string
      companyMember?: {
        __typename?: 'CompanyMemberType'
        id: string
        company: { __typename?: 'CompanyType'; name: string }
      } | null
    }>
  }
}

export type OverviewStatisticsQueryVariables = Exact<{ [key: string]: never }>

export type OverviewStatisticsQuery = {
  __typename?: 'Query'
  overviewStatistics: {
    __typename?: 'OverviewStatisticsType'
    totalPendingBookings: number
    bookedServices: number
    filteredBookings: number
    privateEnergy: number
    registrations: number
    totalFinishedCheckin: number
    totalFinishedSelfscan: number
    workEnergy: number
    totalSeats: number
    seatsInUse: number
    totalPendingBudget: number
    totalSpendBudget: number
    totalAvailableBudget: number
    mostFavoriteThemes: Array<{
      __typename?: 'CompanyThemeType'
      score: number
      theme: {
        __typename?: 'ThemeType'
        id: string
        slug: string
        name: string
        color?: string | null
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
    themes: Array<{
      __typename?: 'CompanyThemeType'
      score: number
      theme: {
        __typename?: 'ThemeType'
        id: string
        slug: string
        name: string
        color?: string | null
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
  }
}

export type FilteredBookingsOverviewQueryVariables = Exact<{
  dateRange?: InputMaybe<StatisticsDateRange>
}>

export type FilteredBookingsOverviewQuery = {
  __typename?: 'Query'
  overviewStatistics: { __typename?: 'OverviewStatisticsType'; filteredBookings: number }
}

export type VendorWithoutUserChatsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type VendorWithoutUserChatsQuery = {
  __typename?: 'Query'
  vendorWithoutUserChats: {
    __typename?: 'ChatListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ChatType'
      lastSent?: any | null
      messages: {
        __typename?: 'MessageListResponse'
        items: Array<{ __typename?: 'MessageType'; content: string }>
      }
      participant1: { __typename?: 'ParticipantType'; name: string; email: string; id: string }
      participant2: { __typename?: 'ParticipantType'; name: string; email: string; id: string }
    }>
  }
}

export type NotFollowedChatsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type NotFollowedChatsQuery = {
  __typename?: 'Query'
  notFollowedChats: {
    __typename?: 'ChatListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ChatType'
      lastSent?: any | null
      messages: {
        __typename?: 'MessageListResponse'
        items: Array<{
          __typename?: 'MessageType'
          content: string
          sender: { __typename?: 'DjangoModelType'; pk: string }
        }>
      }
      participant1: { __typename?: 'ParticipantType'; name: string; email: string; id: string }
      participant2: { __typename?: 'ParticipantType'; name: string; email: string; id: string }
    }>
  }
}

export type ExportScansQueryVariables = Exact<{
  excludeIds?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type ExportScansQuery = {
  __typename?: 'Query'
  exportScans: {
    __typename?: 'ExportedScanListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ExportedScanType'
      userId: string
      organizationId: string
      scanType: string
      date: string
      bookedProducts: string
      scoredQuestions: Array<{ __typename?: 'ScoreType'; id: string; name: string; score: string }>
      scoredThemes: Array<{ __typename?: 'ScoreType'; id: string; name: string; score: string }>
    }>
  }
}

export type RegisterWebinarMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RegisterWebinarMutation = { __typename?: 'Mutation'; registerWebinar: boolean }

export type ThemeFragmentFragment = {
  __typename?: 'ThemeType'
  id: string
  name: string
  slug: string
  color?: string | null
  description: string
  icon?: { __typename?: 'Image'; url: string } | null
  highlights: Array<{
    __typename?: 'HighlightType'
    highlightedItem:
      | ({ __typename: 'ContentPageType' } & {
          ' $fragmentRefs'?: {
            HighLightedContentPageFragmentFragment: HighLightedContentPageFragmentFragment
          }
        })
      | ({ __typename: 'ProductType' } & {
          ' $fragmentRefs'?: { ProductFragmentFragment: ProductFragmentFragment }
        })
      | ({ __typename: 'VendorType' } & {
          ' $fragmentRefs'?: { VendorFragmentFragment: VendorFragmentFragment }
        })
  }>
} & { ' $fragmentName'?: 'ThemeFragmentFragment' }

export type UserThemeFragmentFragment = {
  __typename?: 'UserThemeType'
  id: string
  isFavorite: boolean
  score?: any | null
  theme: { __typename?: 'ThemeType' } & {
    ' $fragmentRefs'?: { ThemeFragmentFragment: ThemeFragmentFragment }
  }
} & { ' $fragmentName'?: 'UserThemeFragmentFragment' }

export type DashboardQueryVariables = Exact<{ [key: string]: never }>

export type DashboardQuery = {
  __typename?: 'Query'
  dashboard: {
    __typename?: 'Dashboard'
    showScan: ShowScanOptions
    userThemes: Array<
      { __typename?: 'UserThemeType' } & {
        ' $fragmentRefs'?: { UserThemeFragmentFragment: UserThemeFragmentFragment }
      }
    >
    themes: Array<
      { __typename?: 'ThemeType' } & {
        ' $fragmentRefs'?: { ThemeFragmentFragment: ThemeFragmentFragment }
      }
    >
    contentPages: Array<
      { __typename?: 'ContentPageType' } & {
        ' $fragmentRefs'?: { ContentPageFragmentFragment: ContentPageFragmentFragment }
      }
    >
  }
}

export type FaqQueryQueryVariables = Exact<{ [key: string]: never }>

export type FaqQueryQuery = {
  __typename?: 'Query'
  faqs: Array<{ __typename?: 'Faq'; id: string; question: string; answer: string }>
}

export type WebinarsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type WebinarsQuery = {
  __typename?: 'Query'
  activeWebinars: {
    __typename?: 'WebinarListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'WebinarType'
      id: string
      name: string
      registered: boolean
      description: string
      startAt: any
      endAt: any
      externalLink?: string | null
      image?: { __typename?: 'Image'; url: string } | null
      vendor: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      }
    }>
  }
}

export type AdminBookingApprovalMutationVariables = Exact<{
  input: ApproveBookingInput
}>

export type AdminBookingApprovalMutation = {
  __typename?: 'Mutation'
  adminBookingApproval:
    | { __typename?: 'BookingType'; id: string }
    | { __typename?: 'OperationInfo' }
}

export type UpdateBillingStatusMutationVariables = Exact<{
  input?: InputMaybe<UpdateBillingStatusInput>
}>

export type UpdateBillingStatusMutation = {
  __typename?: 'Mutation'
  updateBillingStatus: { __typename?: 'BookingType'; id: string } | { __typename?: 'OperationInfo' }
}

export type VendorBookingApprovalMutationVariables = Exact<{
  input: ApproveBookingInput
}>

export type VendorBookingApprovalMutation = {
  __typename?: 'Mutation'
  vendorBookingApproval:
    | { __typename?: 'BookingType'; id: string }
    | { __typename?: 'OperationInfo' }
}

export type SuggestBookingDateMutationVariables = Exact<{
  input: SuggestDateInput
}>

export type SuggestBookingDateMutation = {
  __typename?: 'Mutation'
  suggestBookingDate: { __typename?: 'BookingType'; id: string } | { __typename?: 'OperationInfo' }
}

export type VendorStatisticsQueryVariables = Exact<{ [key: string]: never }>

export type VendorStatisticsQuery = {
  __typename?: 'Query'
  vendorStatistics: {
    __typename?: 'VendorStatisticsType'
    bookedServices: number
    receiveBudget: number
  }
}

export type BookingsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<BookingFilter>
}>

export type BookingsQuery = {
  __typename?: 'Query'
  bookings: {
    __typename?: 'BookingListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'BookingType'
      id: string
      status: BookingsBookingStatusEnum
      date?: any | null
      price: any
      createdAt: any
      vendorBillingStatus: BookingsBookingVendorBillingStatusEnum
      companyBillingStatus: BookingsBookingCompanyBillingStatusEnum
      employeeComment: string
      employee: {
        __typename?: 'Employee'
        id: string
        user: {
          __typename?: 'UserType'
          firstName: string
          lastName: string
          email: string
          phoneNumber?: string | null
        }
        company: { __typename?: 'CompanyType'; name: string }
      }
      product: {
        __typename?: 'ProductType'
        name: string
        slug: string
        price: any
        child?:
          | { __typename: 'ItemType'; id: string }
          | { __typename: 'ServiceType'; numberOfSessions?: number | null }
          | null
        vendor: {
          __typename?: 'VendorType'
          name: string
          slug: string
          smallImage?: { __typename?: 'Image'; url: string } | null
        }
      }
      actions: Array<{ __typename?: 'BookingActionType'; date?: any | null }>
    }>
  }
}

export type SharedUserInfoListQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  search?: InputMaybe<Scalars['String']['input']>
}>

export type SharedUserInfoListQuery = {
  __typename?: 'Query'
  sharedUserInfoList: {
    __typename?: 'SharedUserInfoListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'SharedUserInfoType'
      slug: string
      user: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        email: string
        companyMember?: {
          __typename?: 'CompanyMemberType'
          company: { __typename?: 'CompanyType'; name: string }
        } | null
      }
    }>
  }
}

export type FavoriteProductsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type FavoriteProductsQuery = {
  __typename?: 'Query'
  favoriteProducts: {
    __typename?: 'ProductListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ProductType'
      id: string
      name: string
      slug: string
      description: string
      usePlaceholderImage: boolean
      price: any
      priceAlterText?: ProductsProductPriceAlterTextEnum | null
      template: ProductsProductTemplateEnum
      image?: { __typename?: 'Image'; url: string } | null
      images?: Array<{
        __typename?: 'ProductImagesType'
        image?: { __typename?: 'Image'; url: string } | null
      }> | null
      vendor: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      }
      child?:
        | { __typename: 'ItemType'; id: string }
        | { __typename: 'ServiceType'; id: string; numberOfSessions?: number | null }
        | null
    }>
  }
}

export type FavoriteVendorsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type FavoriteVendorsQuery = {
  __typename?: 'Query'
  favoriteVendors: {
    __typename?: 'VendorListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'VendorType'
      name: string
      slug: string
      id: string
      description: string
      image?: { __typename?: 'Image'; url: string } | null
    }>
  }
}

export type FavoriteContentPagesQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type FavoriteContentPagesQuery = {
  __typename?: 'Query'
  favoriteContentPages: {
    __typename?: 'ContentPageListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ContentPageType'
      id: string
      title: string
      slug: string
      intro: string
      mainImage?: { __typename?: 'Image'; id: string; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: {
          __typename?: 'Image'
          alt: string
          height: number
          id: string
          url: string
          width: number
        } | null
      } | null
      child?:
        | { __typename: 'BlogType'; id: string }
        | { __typename: 'GuideType'; id: string }
        | null
    }>
  }
}

export type GuideQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type GuideQuery = {
  __typename?: 'Query'
  guide: {
    __typename?: 'GuideType'
    id: string
    title: string
    slug: string
    intro: string
    body: any
    author?: string | null
    footerHeader?: string | null
    footerText?: string | null
    chapters: Array<{ __typename?: 'ChapterType'; header: string; body: any }>
    mainImage?: { __typename?: 'Image'; id: string; url: string } | null
    vendor?: {
      __typename?: 'VendorType'
      id: string
      name: string
      slug: string
      smallImage?: {
        __typename?: 'Image'
        alt: string
        height: number
        id: string
        url: string
        width: number
      } | null
    } | null
    images?: Array<{ __typename?: 'Image'; baseImageId: string; url: string }> | null
    footerIcon?: { __typename?: 'Image'; url: string } | null
    themes: Array<{
      __typename?: 'ThemeType'
      id: string
      name: string
      icon?: { __typename?: 'Image'; url: string } | null
    }>
    tags: Array<{ __typename?: 'TagType'; id: string; name: string }>
  }
}

export type GuidesQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type GuidesQuery = {
  __typename?: 'Query'
  guides: {
    __typename?: 'GuideListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'GuideType'
      id: string
      title: string
      slug: string
      intro: string
      mainImage?: { __typename?: 'Image'; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      } | null
      images?: Array<{ __typename?: 'Image'; url: string }> | null
      child?: { __typename: 'BlogType' } | { __typename: 'GuideType' } | null
    }>
  }
}

export type RequestEmailLoginCodeMutationVariables = Exact<{
  email: Scalars['Email']['input']
}>

export type RequestEmailLoginCodeMutation = { __typename?: 'Mutation'; requestEmailLoginCode: any }

export type RequestTextLoginCodeMutationVariables = Exact<{
  emailCode: Scalars['String']['input']
  changePhoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}>

export type RequestTextLoginCodeMutation = { __typename?: 'Mutation'; requestTextLoginCode: any }

export type LoginMutationVariables = Exact<{
  textCode: Scalars['String']['input']
  emailCode: Scalars['String']['input']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'UserType'
    id: string
    email: string
    firstName: string
    lastName: string
    phoneNumber?: string | null
    familyStatus: UsersUserFamilyStatusEnum
    gender: UsersUserGenderEnum
    dateOfBirth?: any | null
    language: UsersUserLanguageEnum
    companyMember?: {
      __typename?: 'CompanyMemberType'
      isOwner: boolean
      isAdmin: boolean
      isEmployee: boolean
      budget?: any | null
      totalBudget?: any | null
      workEnergy?: any | null
      privateEnergy?: any | null
      company: { __typename?: 'CompanyType'; name: string; slug: string; budgetPerEmployee: any }
    } | null
    vendorMember?: {
      __typename?: 'VendorMemberType'
      vendor: {
        __typename?: 'VendorType'
        name: string
        slug: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      }
    } | null
    userThemes: Array<{ __typename?: 'UserThemeType'; id: string }>
  } | null
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean }

export type AcceptTermsMutationVariables = Exact<{
  input: AcceptTermsInput
}>

export type AcceptTermsMutation = {
  __typename?: 'Mutation'
  acceptTerms: {
    __typename?: 'Terms'
    createdAt: any
    version: number
    content: string
    accepted: boolean
  }
}

export type FillRegisterInfoMutationVariables = Exact<{
  code: Scalars['String']['input']
  input: UserInput
}>

export type FillRegisterInfoMutation = {
  __typename?: 'Mutation'
  fillInRegisterInfo:
    | { __typename?: 'OperationInfo' }
    | { __typename?: 'UserType'; email: string; phoneNumber?: string | null }
}

export type EmailLoginInfoQueryVariables = Exact<{
  emailCode: Scalars['String']['input']
}>

export type EmailLoginInfoQuery = {
  __typename?: 'Query'
  emailLoginInfo: {
    __typename?: 'LoginInfo'
    user: {
      __typename?: 'UserType'
      firstName: string
      lastName: string
      email: string
      phoneNumber?: string | null
    }
    organization:
      | { __typename?: 'CompanyType'; name: string }
      | { __typename?: 'VendorType'; name: string }
  }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'UserType'
    id: string
    email: string
    firstName: string
    lastName: string
    phoneNumber?: string | null
    familyStatus: UsersUserFamilyStatusEnum
    gender: UsersUserGenderEnum
    dateOfBirth?: any | null
    language: UsersUserLanguageEnum
    isSuperuser: boolean
    isStaff: boolean
    isCustomerSupport: boolean
    lastLogin?: any | null
    companyMember?: {
      __typename?: 'CompanyMemberType'
      isOwner: boolean
      isAdmin: boolean
      isEmployee: boolean
      budget?: any | null
      totalBudget?: any | null
      workEnergy?: any | null
      privateEnergy?: any | null
      company: {
        __typename?: 'CompanyType'
        name: string
        slug: string
        budgetPerEmployee: any
        maxBudgetPerEmployee: any
        companyBudget: any
        accountType: OrganizationsCompanyAccountTypeEnum
        logo?: { __typename?: 'Image'; url: string } | null
      }
    } | null
    vendorMember?: {
      __typename?: 'VendorMemberType'
      vendor: {
        __typename?: 'VendorType'
        name: string
        slug: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      }
    } | null
    userThemes: Array<{
      __typename?: 'UserThemeType'
      id: string
      isFavorite: boolean
      theme: {
        __typename?: 'ThemeType'
        name: string
        slug: string
        icon?: { __typename?: 'Image'; url: string } | null
      }
    }>
  } | null
}

export type TermsQueryQueryVariables = Exact<{ [key: string]: never }>

export type TermsQueryQuery = {
  __typename?: 'Query'
  terms: {
    __typename?: 'Terms'
    accepted: boolean
    content: string
    createdAt: any
    version: number
  }
}

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput
}>

export type SendMessageMutation = {
  __typename?: 'Mutation'
  sendMessage: { __typename?: 'MessageType'; id: string }
}

export type ReadChatMutationVariables = Exact<{
  chatId: Scalars['ID']['input']
}>

export type ReadChatMutation = {
  __typename?: 'Mutation'
  readChat: { __typename?: 'ChatType'; id: string }
}

export type CreateChatMutationVariables = Exact<{
  input: CreateChatInput
}>

export type CreateChatMutation = {
  __typename?: 'Mutation'
  createChat: {
    __typename?: 'ChatType'
    id: string
    participant2: { __typename?: 'ParticipantType'; name: string }
    participant1: { __typename?: 'ParticipantType'; isCurrentUser: boolean }
  }
}

export type BookingDateApprovalMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type BookingDateApprovalMutation = {
  __typename?: 'Mutation'
  bookingDateApproval: { __typename?: 'BookingType'; id: string } | { __typename?: 'OperationInfo' }
}

export type SendEmailForMessageByAdminMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SendEmailForMessageByAdminMutation = {
  __typename?: 'Mutation'
  sendEmailForMessageByAdmin: any
}

export type SendEmailForMessageMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SendEmailForMessageMutation = { __typename?: 'Mutation'; sendEmailForMessage: any }

export type UpdateMessageMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateMessageInput
}>

export type UpdateMessageMutation = {
  __typename?: 'Mutation'
  updateMessage: { __typename?: 'MessageType'; id: string }
}

export type UpdateChatMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateChatInput
}>

export type UpdateChatMutation = {
  __typename?: 'Mutation'
  updateChat: { __typename?: 'ChatType'; id: string }
}

export type ChatContactsQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  filters?: InputMaybe<ChatFilter>
}>

export type ChatContactsQuery = {
  __typename?: 'Query'
  chats: {
    __typename?: 'ChatListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ChatType'
      id: string
      hasUnreadMessage: boolean
      flagged: boolean
      archived: boolean
      participant1: {
        __typename?: 'ParticipantType'
        id: string
        name: string
        isCurrentUser: boolean
      }
      participant2: { __typename?: 'ParticipantType'; id: string; name: string }
      messages: {
        __typename?: 'MessageListResponse'
        items: Array<{
          __typename?: 'MessageType'
          content: string
          readAt?: any | null
          createdAt: any
          type?: string | null
        }>
      }
    }>
  }
}

export type MessagesQueryVariables = Exact<{
  chatId: Scalars['ID']['input']
  pagination?: InputMaybe<OffsetPaginationInput>
  filters?: InputMaybe<MessageFilter>
  search?: InputMaybe<Scalars['String']['input']>
}>

export type MessagesQuery = {
  __typename?: 'Query'
  chat: {
    __typename?: 'ChatType'
    messages: {
      __typename?: 'MessageListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'MessageType'
        id: string
        direction: string
        content: string
        createdAt: any
        type?: string | null
        sentEmailAt?: any | null
        readAt?: any | null
        archived: boolean
        markedUnread: boolean
        flagged: boolean
        senderEmail: string
      }>
    }
  }
}

export type HasUnreadChatQueryVariables = Exact<{ [key: string]: never }>

export type HasUnreadChatQuery = {
  __typename?: 'Query'
  hasUnreadChat: { __typename?: 'UnreadChatType'; normal: boolean; fromCustomerSupport: boolean }
}

export type BookingQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type BookingQuery = {
  __typename?: 'Query'
  booking: { __typename?: 'BookingType'; status: BookingsBookingStatusEnum }
}

export type ChatTemplateQueryVariables = Exact<{ [key: string]: never }>

export type ChatTemplateQuery = {
  __typename?: 'Query'
  chatTemplates: Array<{ __typename?: 'ChatTemplateType'; content: string; id: string }>
}

export type ServiceQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type ServiceQuery = {
  __typename?: 'Query'
  service: {
    __typename: 'ServiceType'
    id: string
    name: string
    slug: string
    description: string
    richDescription: any
    template: ProductsProductTemplateEnum
    numberOfSessions?: number | null
    price: any
    usePlaceholderImage: boolean
    priceAlterText?: ProductsProductPriceAlterTextEnum | null
    vendor: { __typename?: 'VendorType' } & {
      ' $fragmentRefs'?: { VendorFragmentFragment: VendorFragmentFragment }
    }
    image?: { __typename?: 'Image'; url: string } | null
    images?: Array<{
      __typename?: 'ProductImagesType'
      image?: { __typename?: 'Image'; url: string } | null
    }> | null
    descriptionImages?: Array<{ __typename?: 'Image'; baseImageId: string; url: string }> | null
    locationGroup?: {
      __typename?: 'LocationGroupType'
      name: string
      locations: Array<{ __typename?: 'LocationType'; id: string; name: string }>
    } | null
    languages: Array<{ __typename?: 'LanguageType'; code: string }>
    themes: Array<{ __typename?: 'ThemeType'; id: string }>
    tags: Array<{ __typename?: 'TagType'; id: string }>
  }
}

export type HighLightedContentPageFragmentFragment = {
  __typename?: 'ContentPageType'
  id: string
  title: string
  slug: string
  intro: string
  mainImage?: { __typename?: 'Image'; id: string; url: string } | null
  child?: { __typename: 'BlogType'; id: string } | { __typename: 'GuideType'; id: string } | null
} & { ' $fragmentName'?: 'HighLightedContentPageFragmentFragment' }

export type VendorFragmentFragment = {
  __typename?: 'VendorType'
  id: string
  name: string
  slug: string
  description: string
  city: string
  image?: { __typename?: 'Image'; url: string } | null
  smallImage?: { __typename?: 'Image'; url: string } | null
  tags: Array<{ __typename?: 'TagType'; name: string }>
  themes: Array<{ __typename?: 'ThemeType'; name: string }>
  locationGroup?: { __typename?: 'LocationGroupType'; name: string } | null
} & { ' $fragmentName'?: 'VendorFragmentFragment' }

export type ProductFragmentFragment = {
  __typename?: 'ProductType'
  id: string
  name: string
  slug: string
  description: string
  price: any
  usePlaceholderImage: boolean
  image?: { __typename?: 'Image'; url: string } | null
  vendor: { __typename?: 'VendorType' } & {
    ' $fragmentRefs'?: { VendorFragmentFragment: VendorFragmentFragment }
  }
  child?:
    | { __typename: 'ItemType'; id: string }
    | { __typename: 'ServiceType'; id: string; numberOfSessions?: number | null }
    | null
} & { ' $fragmentName'?: 'ProductFragmentFragment' }

export type ContentPageFragmentFragment = {
  __typename?: 'ContentPageType'
  id: string
  title: string
  slug: string
  intro: string
  mainImage?: { __typename?: 'Image'; id: string; url: string } | null
  vendor?: {
    __typename?: 'VendorType'
    name: string
    smallImage?: {
      __typename?: 'Image'
      alt: string
      height: number
      id: string
      url: string
      width: number
    } | null
  } | null
  child?:
    | { __typename: 'BlogType'; id: string; slug: string }
    | { __typename: 'GuideType'; id: string; slug: string }
    | null
} & { ' $fragmentName'?: 'ContentPageFragmentFragment' }

export type PdpDataQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type PdpDataQuery = {
  __typename?: 'Query'
  productDetailPage: {
    __typename?: 'ProductDetailPage'
    name: string
    slug: string
    score: number
    descriptionHeader: string
    description: string
    icon?: { __typename?: 'Image'; url: string } | null
    tags: Array<{ __typename?: 'TagType'; id: string; name: string; slug: string }>
    highlights: Array<{
      __typename?: 'HighlightType'
      highlightedItem:
        | {
            __typename: 'ContentPageType'
            id: string
            child?:
              | { __typename: 'BlogType'; id: string }
              | { __typename: 'GuideType'; id: string }
              | null
          }
        | {
            __typename: 'ProductType'
            id: string
            child?:
              | { __typename: 'ItemType'; id: string }
              | { __typename: 'ServiceType'; id: string }
              | null
          }
        | { __typename: 'VendorType'; id: string }
    }>
  }
}

export type ProductsQueryVariables = Exact<{
  slug: Scalars['String']['input']
  filter?: InputMaybe<ProductFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type ProductsQuery = {
  __typename?: 'Query'
  productDetailPage: {
    __typename?: 'ProductDetailPage'
    products: {
      __typename?: 'ProductListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'ProductType'
        id: string
        name: string
        slug: string
        description: string
        price: any
        priceAlterText?: ProductsProductPriceAlterTextEnum | null
        template: ProductsProductTemplateEnum
        usePlaceholderImage: boolean
        image?: { __typename?: 'Image'; url: string } | null
        images?: Array<{
          __typename?: 'ProductImagesType'
          image?: { __typename?: 'Image'; url: string } | null
        }> | null
        vendor: {
          __typename?: 'VendorType'
          name: string
          smallImage?: { __typename?: 'Image'; url: string } | null
        }
        child?:
          | { __typename: 'ItemType'; id: string }
          | { __typename: 'ServiceType'; id: string; numberOfSessions?: number | null }
          | null
      }>
    }
  }
}

export type VendorsQueryVariables = Exact<{
  slug: Scalars['String']['input']
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type VendorsQuery = {
  __typename?: 'Query'
  productDetailPage: {
    __typename?: 'ProductDetailPage'
    vendors: {
      __typename?: 'VendorListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'VendorType'
        name: string
        slug: string
        id: string
        description: string
        image?: { __typename?: 'Image'; url: string } | null
      }>
    }
  }
}

export type ContentPagesQueryVariables = Exact<{
  slug: Scalars['String']['input']
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type ContentPagesQuery = {
  __typename?: 'Query'
  productDetailPage: {
    __typename?: 'ProductDetailPage'
    contentPages: {
      __typename?: 'ContentPageListResponse'
      totalItemsCount: number
      items: Array<
        { __typename?: 'ContentPageType' } & {
          ' $fragmentRefs'?: { ContentPageFragmentFragment: ContentPageFragmentFragment }
        }
      >
    }
  }
}

export type TagsQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type TagsQuery = {
  __typename?: 'Query'
  productDetailPage: {
    __typename?: 'ProductDetailPage'
    productTags: Array<{ __typename?: 'TagType'; id: string; name: string }>
  }
}

export type VendorQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type VendorQuery = {
  __typename?: 'Query'
  vendor: {
    __typename?: 'VendorType'
    id: string
    name: string
    description: string
    description2: string
    description3: string
    city: string
    website: string
    locationGroup?: { __typename?: 'LocationGroupType'; name: string } | null
    languages: Array<{ __typename?: 'LanguageType'; code: string }>
    studies: Array<{ __typename?: 'StudyType'; name: string }>
    image?: { __typename?: 'Image'; url: string } | null
    smallImage?: { __typename?: 'Image'; url: string } | null
    tags: Array<{ __typename?: 'TagType'; id: string; slug: string; name: string }>
    themes: Array<{ __typename?: 'ThemeType'; id: string; slug: string; name: string }>
  }
}

export type VendorProductsQueryVariables = Exact<{
  vendorId?: InputMaybe<Scalars['ID']['input']>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type VendorProductsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'ProductListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'ProductType'
      id: string
      name: string
      slug: string
      description: string
      price: any
      template: ProductsProductTemplateEnum
      priceAlterText?: ProductsProductPriceAlterTextEnum | null
      usePlaceholderImage: boolean
      image?: { __typename?: 'Image'; url: string } | null
      images?: Array<{
        __typename?: 'ProductImagesType'
        image?: { __typename?: 'Image'; url: string } | null
      }> | null
      child?:
        | { __typename: 'ItemType'; id: string }
        | { __typename: 'ServiceType'; id: string; numberOfSessions?: number | null }
        | null
    }>
  }
}

export type ProductItemQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type ProductItemQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'ProductType'
    id: string
    name: string
    slug: string
    description: string
    richDescription: any
    template: ProductsProductTemplateEnum
    price: any
    priceAlterText?: ProductsProductPriceAlterTextEnum | null
    usePlaceholderImage: boolean
    vendor: { __typename?: 'VendorType' } & {
      ' $fragmentRefs'?: { VendorFragmentFragment: VendorFragmentFragment }
    }
    image?: { __typename?: 'Image'; url: string } | null
    images?: Array<{
      __typename?: 'ProductImagesType'
      image?: { __typename?: 'Image'; url: string } | null
    }> | null
    descriptionImages?: Array<{ __typename?: 'Image'; baseImageId: string; url: string }> | null
    themes: Array<{ __typename?: 'ThemeType'; id: string }>
    tags: Array<{ __typename?: 'TagType'; id: string }>
  }
}

export type UpdateInfoMutationMutationVariables = Exact<{
  input: UserInput
}>

export type UpdateInfoMutationMutation = {
  __typename?: 'Mutation'
  updateProfile:
    | { __typename?: 'OperationInfo' }
    | { __typename?: 'UserType'; email: string; phoneNumber?: string | null }
}

export type UpdateFavoriteThemeMutationVariables = Exact<{
  input: UpdateThemeInput
}>

export type UpdateFavoriteThemeMutation = { __typename?: 'Mutation'; updateFavoriteTheme: any }

export type ScoredThemeFragmentFragment = {
  __typename?: 'UserThemeType'
  id: string
  isFavorite: boolean
  score?: any | null
  scanType: UsersUserThemeScanTypeEnum
  theme: {
    __typename?: 'ThemeType'
    id: string
    name: string
    slug: string
    order: number
    description: string
    color?: string | null
    icon?: { __typename?: 'Image'; url: string } | null
  }
  answers: Array<{ __typename?: 'ConvertedAnswer'; answer: string; text: string }>
} & { ' $fragmentName'?: 'ScoredThemeFragmentFragment' }

export type UserThemesQueryVariables = Exact<{ [key: string]: never }>

export type UserThemesQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'UserType'
    userThemes: Array<
      { __typename?: 'UserThemeType' } & {
        ' $fragmentRefs'?: { ScoredThemeFragmentFragment: ScoredThemeFragmentFragment }
      }
    >
    nonThemeAnswers?: Array<{ __typename?: 'ConvertedAnswer'; text: string; answer: string }> | null
  } | null
}

export type TimeLineQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  lte?: InputMaybe<Scalars['Date']['input']>
}>

export type TimeLineQuery = {
  __typename?: 'Query'
  timeline: {
    __typename?: 'TimeLineItemListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'TimeLineItem'
      id: string
      date: any
      itemType: OrganizationsTimelineItemItemTypeEnum
      title?: string | null
      image?: { __typename?: 'Image'; url: string } | null
    }>
  }
}

export type StartScanMutationVariables = Exact<{
  input: StartScanInput
}>

export type StartScanMutation = { __typename?: 'Mutation'; startScan: any }

export type FinishScanMutationVariables = Exact<{
  input: FinishScanInput
}>

export type FinishScanMutation = {
  __typename?: 'Mutation'
  finishScan: { __typename?: 'FinishScanType'; id: string; needSupport: boolean }
}

export type AnswerBinaryQuestionMutationVariables = Exact<{
  input: BinaryAnswerInput
}>

export type AnswerBinaryQuestionMutation = { __typename?: 'Mutation'; answerBinaryQuestion: any }

export type AnswerMultipleChoiceQuestionMutationVariables = Exact<{
  input: MultipleChoiceAnswerInput
}>

export type AnswerMultipleChoiceQuestionMutation = {
  __typename?: 'Mutation'
  answerMultipleChoiceQuestion: any
}

export type AnswerScoreQuestionMutationVariables = Exact<{
  input: ScoreAnswerInput
}>

export type AnswerScoreQuestionMutation = { __typename?: 'Mutation'; answerScoreQuestion: any }

export type AnswerSliderQuestionMutationVariables = Exact<{
  input: SliderAnswerInput
}>

export type AnswerSliderQuestionMutation = { __typename?: 'Mutation'; answerSliderQuestion: any }

export type AnswerTextQuestionMutationVariables = Exact<{
  input: TextAnswerInput
}>

export type AnswerTextQuestionMutation = { __typename?: 'Mutation'; answerTextQuestion: any }

export type SelfScanQueryVariables = Exact<{ [key: string]: never }>

export type SelfScanQuery = {
  __typename?: 'Query'
  selfScan?: {
    __typename?: 'ScanType'
    id: string
    introHeader: string
    introText: any
    outroHeader: string
    outroText: any
    questions: {
      __typename?: 'QuestionListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'QuestionType'
        id: string
        description: string
        title: string
        type: ScansQuestionTypeEnum
        image?: { __typename?: 'Image'; url: string } | null
        child:
          | { __typename?: 'BinaryQuestionType'; id: string }
          | {
              __typename?: 'MultipleChoiceQuestionType'
              id: string
              choices: Array<{ __typename?: 'ChoiceType'; weight?: number | null; text: string }>
            }
          | { __typename?: 'ScoreQuestionType'; id: string; maxScore: number }
          | { __typename?: 'SliderQuestionType'; id: string; maxScore: number }
          | { __typename?: 'TextQuestionType'; id: string }
      }>
    }
  } | null
}

export type CheckInQueryVariables = Exact<{ [key: string]: never }>

export type CheckInQuery = {
  __typename?: 'Query'
  checkIn?: {
    __typename?: 'ScanType'
    id: string
    introHeader: string
    introText: any
    outroHeader: string
    outroText: any
    questions: {
      __typename?: 'QuestionListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'QuestionType'
        id: string
        description: string
        title: string
        type: ScansQuestionTypeEnum
        image?: { __typename?: 'Image'; url: string } | null
        child:
          | { __typename?: 'BinaryQuestionType'; id: string }
          | {
              __typename?: 'MultipleChoiceQuestionType'
              id: string
              choices: Array<{ __typename?: 'ChoiceType'; weight?: number | null; text: string }>
            }
          | { __typename?: 'ScoreQuestionType'; id: string; maxScore: number }
          | { __typename?: 'SliderQuestionType'; id: string; maxScore: number }
          | { __typename?: 'TextQuestionType'; id: string }
      }>
    }
  } | null
}

export type SearchQueryQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  search: Scalars['String']['input']
  filters?: InputMaybe<SearchItemFilter>
  ignoreMixContent?: InputMaybe<Scalars['Boolean']['input']>
  prioritizedOrder?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  isTeamOffer?: InputMaybe<Scalars['Boolean']['input']>
  ignoreMixVendor?: InputMaybe<Scalars['Boolean']['input']>
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type SearchQueryQuery = {
  __typename?: 'Query'
  search: {
    __typename?: 'SearchItemListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'SearchItemType'
      id: string
      text: string
      textEn: string
      category: SearchSearchItemCategoryEnum
      isBook: boolean
      itemId: number
      numberOfSessions?: number | null
      order?: number | null
      price: any
      priceAlterText?: string | null
      usePlaceholderImage?: boolean | null
      image?: { __typename?: 'Image'; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      } | null
    }>
  }
}

export type MiniSearchQueryQueryVariables = Exact<{
  search: Scalars['String']['input']
}>

export type MiniSearchQueryQuery = {
  __typename?: 'Query'
  miniSearch: {
    __typename?: 'MiniSearchResponse'
    blogs: Array<{
      __typename?: 'SearchItemType'
      id: string
      text: string
      textEn: string
      category: SearchSearchItemCategoryEnum
      isBook: boolean
      itemId: number
      numberOfSessions?: number | null
      order?: number | null
      price: any
      priceAlterText?: string | null
      usePlaceholderImage?: boolean | null
      image?: { __typename?: 'Image'; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      } | null
    }>
    products: Array<{
      __typename?: 'SearchItemType'
      id: string
      text: string
      textEn: string
      category: SearchSearchItemCategoryEnum
      isBook: boolean
      itemId: number
      numberOfSessions?: number | null
      order?: number | null
      price: any
      priceAlterText?: string | null
      usePlaceholderImage?: boolean | null
      image?: { __typename?: 'Image'; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      } | null
    }>
    experts: Array<{
      __typename?: 'SearchItemType'
      id: string
      text: string
      textEn: string
      category: SearchSearchItemCategoryEnum
      isBook: boolean
      itemId: number
      numberOfSessions?: number | null
      order?: number | null
      price: any
      priceAlterText?: string | null
      usePlaceholderImage?: boolean | null
      image?: { __typename?: 'Image'; url: string } | null
      vendor?: {
        __typename?: 'VendorType'
        name: string
        smallImage?: { __typename?: 'Image'; url: string } | null
      } | null
    }>
  }
}

export type TagQueryQueryVariables = Exact<{ [key: string]: never }>

export type TagQueryQuery = {
  __typename?: 'Query'
  tags: Array<{ __typename?: 'TagType'; name: string; slug: string; id: string }>
}

export type SearchHistoryQueryQueryVariables = Exact<{
  search: Scalars['String']['input']
}>

export type SearchHistoryQueryQuery = {
  __typename?: 'Query'
  searchHistory: Array<{
    __typename?: 'SearchHistoryType'
    id: string
    search: string
    updatedAt: any
  }>
}

export type ApproveRequestMutationVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type ApproveRequestMutation = { __typename?: 'Mutation'; shareInfoApproval: boolean }

export type RejectRequestMutationVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type RejectRequestMutation = { __typename?: 'Mutation'; shareInfoApproval: boolean }

export type ShareUserInfoQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type ShareUserInfoQuery = {
  __typename?: 'Query'
  sharedUserInfo: {
    __typename?: 'SharedUserInfoType'
    message: string
    status: UsersSharedUserInfoStatusEnum
    vendors: Array<{ __typename?: 'VendorType'; name: string }>
    user: {
      __typename?: 'UserType'
      firstName: string
      lastName: string
      email: string
      companyMember?: {
        __typename?: 'CompanyMemberType'
        workEnergy?: any | null
        privateEnergy?: any | null
        company: { __typename?: 'CompanyType'; name: string }
      } | null
      userThemes: Array<
        { __typename?: 'UserThemeType' } & {
          ' $fragmentRefs'?: { ScoredThemeFragmentFragment: ScoredThemeFragmentFragment }
        }
      >
      nonThemeAnswers?: Array<{
        __typename?: 'ConvertedAnswer'
        text: string
        answer: string
      }> | null
    }
  }
}

export type TagPageQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type TagPageQuery = {
  __typename?: 'Query'
  tagPage: {
    __typename?: 'TagPageType'
    descriptionHeader: string
    description: string
    tag: { __typename?: 'TagType'; name: string; slug: string }
    icon?: { __typename?: 'Image'; url: string } | null
    highlights: Array<{
      __typename?: 'TagHighlightType'
      highlightedItem:
        | ({ __typename: 'ContentPageType' } & {
            ' $fragmentRefs'?: {
              HighLightedContentPageFragmentFragment: HighLightedContentPageFragmentFragment
            }
          })
        | ({ __typename: 'ProductType' } & {
            ' $fragmentRefs'?: { ProductFragmentFragment: ProductFragmentFragment }
          })
        | ({ __typename: 'VendorType' } & {
            ' $fragmentRefs'?: { VendorFragmentFragment: VendorFragmentFragment }
          })
    }>
  }
}

export type TagProductsQueryVariables = Exact<{
  slug: Scalars['String']['input']
  filter?: InputMaybe<ProductFilter>
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type TagProductsQuery = {
  __typename?: 'Query'
  tagPage: {
    __typename?: 'TagPageType'
    products: {
      __typename?: 'ProductListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'ProductType'
        id: string
        name: string
        slug: string
        description: string
        price: any
        priceAlterText?: ProductsProductPriceAlterTextEnum | null
        template: ProductsProductTemplateEnum
        usePlaceholderImage: boolean
        image?: { __typename?: 'Image'; url: string } | null
        images?: Array<{
          __typename?: 'ProductImagesType'
          image?: { __typename?: 'Image'; url: string } | null
        }> | null
        vendor: {
          __typename?: 'VendorType'
          name: string
          smallImage?: { __typename?: 'Image'; url: string } | null
        }
        child?:
          | { __typename: 'ItemType'; id: string }
          | { __typename: 'ServiceType'; id: string; numberOfSessions?: number | null }
          | null
      }>
    }
  }
}

export type TagVendorsQueryVariables = Exact<{
  slug: Scalars['String']['input']
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type TagVendorsQuery = {
  __typename?: 'Query'
  tagPage: {
    __typename?: 'TagPageType'
    vendors: {
      __typename?: 'VendorListResponse'
      totalItemsCount: number
      items: Array<{
        __typename?: 'VendorType'
        name: string
        slug: string
        id: string
        description: string
        image?: { __typename?: 'Image'; url: string } | null
      }>
    }
  }
}

export type TagContentPagesQueryVariables = Exact<{
  slug: Scalars['String']['input']
  pagination?: InputMaybe<OffsetPaginationInput>
}>

export type TagContentPagesQuery = {
  __typename?: 'Query'
  tagPage: {
    __typename?: 'TagPageType'
    contentPages: {
      __typename?: 'ContentPageListResponse'
      totalItemsCount: number
      items: Array<
        { __typename?: 'ContentPageType' } & {
          ' $fragmentRefs'?: { ContentPageFragmentFragment: ContentPageFragmentFragment }
        }
      >
    }
  }
}

export type VendorsOverviewQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>
  filters?: InputMaybe<VendorListFilter>
}>

export type VendorsOverviewQuery = {
  __typename?: 'Query'
  vendors: {
    __typename?: 'VendorListResponse'
    totalItemsCount: number
    items: Array<{
      __typename?: 'VendorType'
      id: string
      name: string
      slug: string
      description: string
      image?: { __typename?: 'Image'; url: string } | null
    }>
  }
}

export type AllTagsQueryQueryVariables = Exact<{ [key: string]: never }>

export type AllTagsQueryQuery = {
  __typename?: 'Query'
  tags: Array<{ __typename?: 'TagType'; name: string; id: string; slug: string }>
}

export type AllThemesQueryQueryVariables = Exact<{ [key: string]: never }>

export type AllThemesQueryQuery = {
  __typename?: 'Query'
  themes: Array<{ __typename?: 'ThemeType'; id: string; name: string; slug: string }>
}

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType']

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value)
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value
  }
}
export const HighLightedContentPageFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment HighLightedContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  child {
    __typename
    ... on BlogType {
      id
    }
    ... on GuideType {
      id
    }
  }
}
    `,
  { fragmentName: 'HighLightedContentPageFragment' },
) as unknown as TypedDocumentString<HighLightedContentPageFragmentFragment, unknown>
export const VendorFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}
    `,
  { fragmentName: 'VendorFragment' },
) as unknown as TypedDocumentString<VendorFragmentFragment, unknown>
export const ProductFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment ProductFragment on ProductType {
  id
  name
  slug
  description
  price
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  usePlaceholderImage
  vendor {
    ...VendorFragment
  }
  child {
    __typename
    ... on ItemType {
      id
    }
    ... on ServiceType {
      id
      numberOfSessions
    }
  }
}
    fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}`,
  { fragmentName: 'ProductFragment' },
) as unknown as TypedDocumentString<ProductFragmentFragment, unknown>
export const ThemeFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment ThemeFragment on ThemeType {
  id
  name
  slug
  icon {
    url
  }
  color
  description
  highlights {
    highlightedItem {
      __typename
      ...HighLightedContentPageFragment
      ...VendorFragment
      ...ProductFragment
    }
  }
}
    fragment HighLightedContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  child {
    __typename
    ... on BlogType {
      id
    }
    ... on GuideType {
      id
    }
  }
}
fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}
fragment ProductFragment on ProductType {
  id
  name
  slug
  description
  price
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  usePlaceholderImage
  vendor {
    ...VendorFragment
  }
  child {
    __typename
    ... on ItemType {
      id
    }
    ... on ServiceType {
      id
      numberOfSessions
    }
  }
}`,
  { fragmentName: 'ThemeFragment' },
) as unknown as TypedDocumentString<ThemeFragmentFragment, unknown>
export const UserThemeFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment UserThemeFragment on UserThemeType {
  id
  theme {
    ...ThemeFragment
  }
  isFavorite
  score
}
    fragment ThemeFragment on ThemeType {
  id
  name
  slug
  icon {
    url
  }
  color
  description
  highlights {
    highlightedItem {
      __typename
      ...HighLightedContentPageFragment
      ...VendorFragment
      ...ProductFragment
    }
  }
}
fragment HighLightedContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  child {
    __typename
    ... on BlogType {
      id
    }
    ... on GuideType {
      id
    }
  }
}
fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}
fragment ProductFragment on ProductType {
  id
  name
  slug
  description
  price
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  usePlaceholderImage
  vendor {
    ...VendorFragment
  }
  child {
    __typename
    ... on ItemType {
      id
    }
    ... on ServiceType {
      id
      numberOfSessions
    }
  }
}`,
  { fragmentName: 'UserThemeFragment' },
) as unknown as TypedDocumentString<UserThemeFragmentFragment, unknown>
export const ContentPageFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment ContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  vendor {
    name
    smallImage(resize: {fill: {width: 64, height: 64}}) {
      alt
      height
      id
      url
      width
    }
  }
  child {
    __typename
    ... on BlogType {
      id
      slug
    }
    ... on GuideType {
      id
      slug
    }
  }
}
    `,
  { fragmentName: 'ContentPageFragment' },
) as unknown as TypedDocumentString<ContentPageFragmentFragment, unknown>
export const ScoredThemeFragmentFragmentDoc = new TypedDocumentString(
  `
    fragment ScoredThemeFragment on UserThemeType {
  id
  theme {
    id
    name
    slug
    order
    description
    color
    icon {
      url
    }
  }
  isFavorite
  score
  scanType
  answers {
    answer
    text
  }
}
    `,
  { fragmentName: 'ScoredThemeFragment' },
) as unknown as TypedDocumentString<ScoredThemeFragmentFragment, unknown>
export const LocationsDocument = new TypedDocumentString(`
    query Locations {
  locations {
    id
    name
    category
  }
}
    `) as unknown as TypedDocumentString<LocationsQuery, LocationsQueryVariables>
export const UpdateLanguageDocument = new TypedDocumentString(`
    mutation UpdateLanguage($language: UsersUserLanguageEnum!) {
  updateLanguage(input: {language: $language}) {
    ... on UserType {
      language
    }
  }
}
    `) as unknown as TypedDocumentString<UpdateLanguageMutation, UpdateLanguageMutationVariables>
export const ThemesQueryDocument = new TypedDocumentString(`
    query ThemesQuery {
  themes {
    id
    name
    slug
    icon {
      url
    }
    highlights {
      highlightedItem {
        ... on ProductType {
          id
          __typename
          child {
            ... on ItemType {
              id
              __typename
            }
            ... on ServiceType {
              id
              __typename
            }
          }
        }
        ... on VendorType {
          id
          __typename
        }
        ... on ContentPageType {
          id
          __typename
          child {
            ... on BlogType {
              id
              __typename
            }
            ... on GuideType {
              id
              __typename
            }
          }
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<ThemesQueryQuery, ThemesQueryQueryVariables>
export const TagPagesDocument = new TypedDocumentString(`
    query TagPages {
  tagPages {
    id
    icon {
      url
    }
    tag {
      slug
      name
    }
  }
}
    `) as unknown as TypedDocumentString<TagPagesQuery, TagPagesQueryVariables>
export const CreateFavoriteMutationDocument = new TypedDocumentString(`
    mutation CreateFavoriteMutation($input: FavoriteInput!) {
  createFavorite(input: $input)
}
    `) as unknown as TypedDocumentString<
  CreateFavoriteMutationMutation,
  CreateFavoriteMutationMutationVariables
>
export const DeleteFavoriteMutationDocument = new TypedDocumentString(`
    mutation DeleteFavoriteMutation($input: FavoriteInput!) {
  deleteFavorite(input: $input)
}
    `) as unknown as TypedDocumentString<
  DeleteFavoriteMutationMutation,
  DeleteFavoriteMutationMutationVariables
>
export const FavoritesDocument = new TypedDocumentString(`
    query Favorites {
  favorites {
    itemId
    type
  }
}
    `) as unknown as TypedDocumentString<FavoritesQuery, FavoritesQueryVariables>
export const BlogDocument = new TypedDocumentString(`
    query Blog($slug: String!) {
  blog(slug: $slug) {
    id
    title
    slug
    intro
    body
    author
    mainImage(resize: {fill: {width: 1280, height: 600}}) {
      id
      url
    }
    vendor {
      name
      slug
      smallImage(resize: {fill: {width: 64, height: 64}}) {
        alt
        height
        id
        url
        width
      }
    }
    images(resize: {max: {width: 800, height: 800}}) {
      baseImageId
      url
    }
    themes {
      __typename
      id
      name
      slug
      icon {
        url
      }
    }
    tags {
      __typename
      id
      name
      slug
    }
  }
}
    `) as unknown as TypedDocumentString<BlogQuery, BlogQueryVariables>
export const BlogsDocument = new TypedDocumentString(`
    query Blogs($pagination: OffsetPaginationInput) {
  blogs(pagination: $pagination) {
    items {
      id
      title
      slug
      intro
      mainImage(resize: {fill: {width: 1280, height: 600}}) {
        url
      }
      vendor {
        name
        smallImage(resize: {fill: {width: 64, height: 64}}) {
          url
        }
      }
      images(resize: {max: {width: 800, height: 800}}) {
        url
      }
      child {
        __typename
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<BlogsQuery, BlogsQueryVariables>
export const CreateBookingDocument = new TypedDocumentString(`
    mutation CreateBooking($input: CreateBookingInput!) {
  createBooking(input: $input) {
    ... on BookingType {
      status
    }
  }
}
    `) as unknown as TypedDocumentString<CreateBookingMutation, CreateBookingMutationVariables>
export const RequestBudgetDocument = new TypedDocumentString(`
    mutation requestBudget($input: BudgetRequestInput!) {
  budgetRequest(input: $input)
}
    `) as unknown as TypedDocumentString<RequestBudgetMutation, RequestBudgetMutationVariables>
export const AcceptPurchaseAgreementDocument = new TypedDocumentString(`
    mutation acceptPurchaseAgreement($input: AcceptTermsInput!) {
  acceptPurchaseAgreement(input: $input) {
    createdAt
    version
    content
    accepted
  }
}
    `) as unknown as TypedDocumentString<
  AcceptPurchaseAgreementMutation,
  AcceptPurchaseAgreementMutationVariables
>
export const PurchaseAgreementDocument = new TypedDocumentString(`
    query PurchaseAgreement {
  purchaseAgreement {
    accepted
    content
    createdAt
    version
  }
}
    `) as unknown as TypedDocumentString<PurchaseAgreementQuery, PurchaseAgreementQueryVariables>
export const CreateSegmentDocument = new TypedDocumentString(`
    mutation CreateSegment($input: SegmentInput!) {
  createSegment(input: $input) {
    id
  }
}
    `) as unknown as TypedDocumentString<CreateSegmentMutation, CreateSegmentMutationVariables>
export const UpdateSegmentDocument = new TypedDocumentString(`
    mutation UpdateSegment($id: ID!, $input: SegmentInput!) {
  updateSegment(id: $id, input: $input) {
    id
  }
}
    `) as unknown as TypedDocumentString<UpdateSegmentMutation, UpdateSegmentMutationVariables>
export const CreateCampaignMessageTemplateDocument = new TypedDocumentString(`
    mutation CreateCampaignMessageTemplate($input: CampaignMessageTemplateInput!) {
  createCampaignMessageTemplate(input: $input) {
    content
    id
    name
  }
}
    `) as unknown as TypedDocumentString<
  CreateCampaignMessageTemplateMutation,
  CreateCampaignMessageTemplateMutationVariables
>
export const UpdateCampaignMessageTemplateDocument = new TypedDocumentString(`
    mutation UpdateCampaignMessageTemplate($id: ID!, $input: CampaignMessageTemplateInput!) {
  updateCampaignMessageTemplate(id: $id, input: $input) {
    content
    id
    name
  }
}
    `) as unknown as TypedDocumentString<
  UpdateCampaignMessageTemplateMutation,
  UpdateCampaignMessageTemplateMutationVariables
>
export const CreateCampaignDocument = new TypedDocumentString(`
    mutation CreateCampaign($input: CampaignInput!) {
  createCampaign(input: $input) {
    message
    name
    id
    sentAt
    segment {
      name
      filters
      contacts {
        totalItemsCount
      }
    }
    scheduledAt
  }
}
    `) as unknown as TypedDocumentString<CreateCampaignMutation, CreateCampaignMutationVariables>
export const UpdateCampaignDocument = new TypedDocumentString(`
    mutation UpdateCampaign($id: ID!, $input: CampaignInput!) {
  updateCampaign(id: $id, input: $input) {
    message
    name
    id
    sentAt
    segment {
      name
      filters
      contacts {
        totalItemsCount
      }
    }
    scheduledAt
  }
}
    `) as unknown as TypedDocumentString<UpdateCampaignMutation, UpdateCampaignMutationVariables>
export const DeleteCampaignDocument = new TypedDocumentString(`
    mutation DeleteCampaign($id: ID!) {
  deleteCampaign(id: $id)
}
    `) as unknown as TypedDocumentString<DeleteCampaignMutation, DeleteCampaignMutationVariables>
export const CampaignUsersDocument = new TypedDocumentString(`
    query CampaignUsers($filters: UserFilter, $pagination: OffsetPaginationInput) {
  campaignUsers(filters: $filters, pagination: $pagination) {
    items {
      firstName
      lastName
      email
      phoneNumber
      id
      lastLogin
      companyMember {
        company {
          name
        }
        budget
      }
      userThemes {
        theme {
          name
        }
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<CampaignUsersQuery, CampaignUsersQueryVariables>
export const SegmentsDocument = new TypedDocumentString(`
    query Segments($pagination: OffsetPaginationInput) {
  segments(pagination: $pagination) {
    items {
      contacts {
        totalItemsCount
      }
      name
      filters
      updatedAt
      id
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<SegmentsQuery, SegmentsQueryVariables>
export const SegmentDetailDocument = new TypedDocumentString(`
    query SegmentDetail($id: ID!, $pagination: OffsetPaginationInput, $search: String) {
  segmentDetail(id: $id) {
    contacts(pagination: $pagination, search: $search) {
      totalItemsCount
      items {
        firstName
        lastName
        email
        id
        lastLogin
        phoneNumber
        companyMember {
          company {
            name
          }
          budget
        }
        userThemes {
          theme {
            name
          }
        }
      }
    }
    name
    filters
    id
    updatedAt
  }
}
    `) as unknown as TypedDocumentString<SegmentDetailQuery, SegmentDetailQueryVariables>
export const MessageTemplatesDocument = new TypedDocumentString(`
    query MessageTemplates($search: String, $pagination: OffsetPaginationInput) {
  campaignMessageTemplates(search: $search, pagination: $pagination) {
    totalItemsCount
    items {
      id
      name
      content
    }
  }
}
    `) as unknown as TypedDocumentString<MessageTemplatesQuery, MessageTemplatesQueryVariables>
export const CampaignsDocument = new TypedDocumentString(`
    query Campaigns($search: String, $pagination: OffsetPaginationInput) {
  campaigns(search: $search, pagination: $pagination) {
    totalItemsCount
    items {
      id
      message
      name
      sentAt
      scheduledAt
      segment {
        contacts {
          totalItemsCount
        }
        id
        name
      }
    }
  }
}
    `) as unknown as TypedDocumentString<CampaignsQuery, CampaignsQueryVariables>
export const BudgetCompanyApprovalDocument = new TypedDocumentString(`
    mutation budgetCompanyApproval($input: BudgetCompanyApprovalInput!) {
  budgetCompanyApproval(input: $input)
}
    `) as unknown as TypedDocumentString<
  BudgetCompanyApprovalMutation,
  BudgetCompanyApprovalMutationVariables
>
export const CreateCompanyEmployeeDocument = new TypedDocumentString(`
    mutation CreateCompanyEmployee($input: CreateUserInput!, $budget: Float, $companyId: ID) {
  createCompanyEmployee(input: $input, budget: $budget, companyId: $companyId)
}
    `) as unknown as TypedDocumentString<
  CreateCompanyEmployeeMutation,
  CreateCompanyEmployeeMutationVariables
>
export const UpdateCompanyEmployeeDocument = new TypedDocumentString(`
    mutation UpdateCompanyEmployee($input: CreateUserInput!, $companyId: ID, $budget: Float) {
  updateCompanyEmployee(input: $input, companyId: $companyId, budget: $budget)
}
    `) as unknown as TypedDocumentString<
  UpdateCompanyEmployeeMutation,
  UpdateCompanyEmployeeMutationVariables
>
export const DeleteCompanyEmployeeDocument = new TypedDocumentString(`
    mutation DeleteCompanyEmployee($id: ID!, $companyId: ID) {
  deleteCompanyEmployee(id: $id, companyId: $companyId)
}
    `) as unknown as TypedDocumentString<
  DeleteCompanyEmployeeMutation,
  DeleteCompanyEmployeeMutationVariables
>
export const FreeTierBudgetRequestDocument = new TypedDocumentString(`
    mutation FreeTierBudgetRequest($input: FreeTierBudgetRequestInput!) {
  freeTierBudgetRequest(input: $input)
}
    `) as unknown as TypedDocumentString<
  FreeTierBudgetRequestMutation,
  FreeTierBudgetRequestMutationVariables
>
export const SendInviteDocument = new TypedDocumentString(`
    mutation SendInvite($employeeId: ID!) {
  sendInvite(employeeId: $employeeId)
}
    `) as unknown as TypedDocumentString<SendInviteMutation, SendInviteMutationVariables>
export const CompanyStatisticsDocument = new TypedDocumentString(`
    query CompanyStatistics {
  companyStatistics {
    privateEnergy
    seatsInUse
    totalBookedServices
    totalPendingBookings
    totalSeats
    workEnergy
    totalUsersFinishedScan
    totalFinishedSelfscan
    totalFinishedCheckin
    themes {
      score
      theme {
        id
        slug
        name
        color
        icon {
          url
        }
      }
    }
    mostFavoriteThemes {
      score
      theme {
        id
        slug
        name
        color
        icon {
          url
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<CompanyStatisticsQuery, CompanyStatisticsQueryVariables>
export const FilteredCompanyStatisticsDocument = new TypedDocumentString(`
    query FilteredCompanyStatistics($slug: String, $dateRange: StatisticsDateRange = QUARTER) {
  companyStatistics(slug: $slug, dateRange: $dateRange) {
    filteredBookedServices
  }
}
    `) as unknown as TypedDocumentString<
  FilteredCompanyStatisticsQuery,
  FilteredCompanyStatisticsQueryVariables
>
export const BudgetRequestsDocument = new TypedDocumentString(`
    query budgetRequests($pagination: OffsetPaginationInput, $search: String, $status: String) {
  budgetRequests(pagination: $pagination, search: $search, status: $status) {
    items {
      id
      amount
      createdAt
      updatedAt
      approvedByCompany
      message
      product {
        name
        slug
        price
        child {
          __typename
          ... on ServiceType {
            numberOfSessions
          }
          ... on ItemType {
            id
          }
        }
        vendor {
          name
          slug
          smallImage(resize: {fill: {width: 64, height: 64}}) {
            url
          }
        }
      }
      employee {
        budget
        user {
          lastName
          firstName
        }
      }
    }
    totalItemsCount
    outstandingItemsCount
  }
}
    `) as unknown as TypedDocumentString<BudgetRequestsQuery, BudgetRequestsQueryVariables>
export const CompanyMembersDocument = new TypedDocumentString(`
    query CompanyMembers($slug: String, $search: String, $selfscan: String, $status: String, $pagination: OffsetPaginationInput) {
  companyMembers(
    slug: $slug
    search: $search
    selfscan: $selfscan
    status: $status
    pagination: $pagination
  ) {
    totalItemsCount
    items {
      id
      inactive
      user {
        id
        email
        lastName
        firstName
        phoneNumber
        lastLogin
        userThemes {
          id
        }
      }
      budget
    }
  }
}
    `) as unknown as TypedDocumentString<CompanyMembersQuery, CompanyMembersQueryVariables>
export const CompanyDocument = new TypedDocumentString(`
    query Company {
  company {
    totalSeats
    seatsInUse
  }
}
    `) as unknown as TypedDocumentString<CompanyQuery, CompanyQueryVariables>
export const MostChosenSearchesDocument = new TypedDocumentString(`
    query MostChosenSearches {
  mostChosenSearches {
    id
    order
    text
    linkedItems
  }
}
    `) as unknown as TypedDocumentString<MostChosenSearchesQuery, MostChosenSearchesQueryVariables>
export const MarkSupportedUserDocument = new TypedDocumentString(`
    mutation MarkSupportedUser($userId: ID!) {
  markSupportedUser(userId: $userId)
}
    `) as unknown as TypedDocumentString<
  MarkSupportedUserMutation,
  MarkSupportedUserMutationVariables
>
export const BulkUpdateEmployeeBudgetDocument = new TypedDocumentString(`
    mutation BulkUpdateEmployeeBudget($slug: String!, $budget: Float!) {
  bulkUpdateEmployeeBudget(slug: $slug, budget: $budget)
}
    `) as unknown as TypedDocumentString<
  BulkUpdateEmployeeBudgetMutation,
  BulkUpdateEmployeeBudgetMutationVariables
>
export const SynchronizeSendgridDocument = new TypedDocumentString(`
    mutation SynchronizeSendgrid {
  synchronizeUsersToSendgrid
  createSendgridSegments
}
    `) as unknown as TypedDocumentString<
  SynchronizeSendgridMutation,
  SynchronizeSendgridMutationVariables
>
export const CompaniesDocument = new TypedDocumentString(`
    query Companies($search: String, $pagination: OffsetPaginationInput) {
  companies(search: $search, pagination: $pagination) {
    items {
      id
      slug
      name
      seatsInUse
      totalSeats
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<CompaniesQuery, CompaniesQueryVariables>
export const CompanyDetailDocument = new TypedDocumentString(`
    query CompanyDetail($slug: String!) {
  companyStatistics(slug: $slug) {
    company {
      id
      name
      budgetPerEmployee
      registeredMembers
    }
    seatsInUse
    totalSeats
    totalBookedServices
    totalPendingBookings
    totalUsersFinishedScan
    totalPendingBudget
    totalSpendBudget
    totalAvailableBudget
    mostFavoriteThemes {
      score
      theme {
        id
        slug
        name
        color
        icon {
          url
        }
      }
    }
    privateEnergy
    themes {
      score
      theme {
        id
        slug
        name
        color
        icon {
          url
        }
      }
    }
    totalFinishedCheckin
    totalFinishedSelfscan
    workEnergy
  }
}
    `) as unknown as TypedDocumentString<CompanyDetailQuery, CompanyDetailQueryVariables>
export const UserInfoDocument = new TypedDocumentString(`
    query UserInfo($id: ID!) {
  userInfo(id: $id) {
    firstName
    lastName
    email
    companyMember {
      company {
        name
      }
      workEnergy
      privateEnergy
    }
    userThemes {
      ...ScoredThemeFragment
    }
    nonThemeAnswers {
      text
      answer
    }
  }
}
    fragment ScoredThemeFragment on UserThemeType {
  id
  theme {
    id
    name
    slug
    order
    description
    color
    icon {
      url
    }
  }
  isFavorite
  score
  scanType
  answers {
    answer
    text
  }
}`) as unknown as TypedDocumentString<UserInfoQuery, UserInfoQueryVariables>
export const NeedSupportUsersDocument = new TypedDocumentString(`
    query NeedSupportUsers($pagination: OffsetPaginationInput) {
  needSupportUsers(pagination: $pagination) {
    items {
      id
      firstName
      lastName
      email
      companyMember {
        id
        company {
          name
        }
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<NeedSupportUsersQuery, NeedSupportUsersQueryVariables>
export const OverviewStatisticsDocument = new TypedDocumentString(`
    query OverviewStatistics {
  overviewStatistics {
    totalPendingBookings
    bookedServices
    filteredBookings
    mostFavoriteThemes {
      score
      theme {
        id
        slug
        name
        color
        icon {
          url
        }
      }
    }
    privateEnergy
    registrations
    themes {
      score
      theme {
        id
        slug
        name
        color
        icon {
          url
        }
      }
    }
    totalFinishedCheckin
    totalFinishedSelfscan
    workEnergy
    totalSeats
    seatsInUse
    totalPendingBudget
    totalSpendBudget
    totalAvailableBudget
  }
}
    `) as unknown as TypedDocumentString<OverviewStatisticsQuery, OverviewStatisticsQueryVariables>
export const FilteredBookingsOverviewDocument = new TypedDocumentString(`
    query FilteredBookingsOverview($dateRange: StatisticsDateRange = QUARTER) {
  overviewStatistics(dateRange: $dateRange) {
    filteredBookings
  }
}
    `) as unknown as TypedDocumentString<
  FilteredBookingsOverviewQuery,
  FilteredBookingsOverviewQueryVariables
>
export const VendorWithoutUserChatsDocument = new TypedDocumentString(`
    query VendorWithoutUserChats($pagination: OffsetPaginationInput) {
  vendorWithoutUserChats(pagination: $pagination) {
    totalItemsCount
    items {
      lastSent
      messages(pagination: {offset: 0, limit: 1}) {
        items {
          content
        }
      }
      participant1 {
        name
        email
        id
      }
      participant2 {
        name
        email
        id
      }
    }
  }
}
    `) as unknown as TypedDocumentString<
  VendorWithoutUserChatsQuery,
  VendorWithoutUserChatsQueryVariables
>
export const NotFollowedChatsDocument = new TypedDocumentString(`
    query NotFollowedChats($pagination: OffsetPaginationInput) {
  notFollowedChats(pagination: $pagination) {
    totalItemsCount
    items {
      lastSent
      messages(pagination: {offset: 0, limit: 1}) {
        items {
          content
          sender {
            pk
          }
        }
      }
      participant1 {
        name
        email
        id
      }
      participant2 {
        name
        email
        id
      }
    }
  }
}
    `) as unknown as TypedDocumentString<NotFollowedChatsQuery, NotFollowedChatsQueryVariables>
export const ExportScansDocument = new TypedDocumentString(`
    query ExportScans($excludeIds: String, $pagination: OffsetPaginationInput) {
  exportScans(excludeIds: $excludeIds, pagination: $pagination) {
    items {
      userId
      organizationId
      scanType
      scoredQuestions {
        id
        name
        score
      }
      scoredThemes {
        id
        name
        score
      }
      date
      bookedProducts
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<ExportScansQuery, ExportScansQueryVariables>
export const RegisterWebinarDocument = new TypedDocumentString(`
    mutation RegisterWebinar($id: ID!) {
  registerWebinar(id: $id)
}
    `) as unknown as TypedDocumentString<RegisterWebinarMutation, RegisterWebinarMutationVariables>
export const DashboardDocument = new TypedDocumentString(`
    query Dashboard {
  dashboard {
    showScan
    userThemes {
      ...UserThemeFragment
    }
    themes {
      ...ThemeFragment
    }
    contentPages {
      ...ContentPageFragment
    }
  }
}
    fragment ThemeFragment on ThemeType {
  id
  name
  slug
  icon {
    url
  }
  color
  description
  highlights {
    highlightedItem {
      __typename
      ...HighLightedContentPageFragment
      ...VendorFragment
      ...ProductFragment
    }
  }
}
fragment UserThemeFragment on UserThemeType {
  id
  theme {
    ...ThemeFragment
  }
  isFavorite
  score
}
fragment HighLightedContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  child {
    __typename
    ... on BlogType {
      id
    }
    ... on GuideType {
      id
    }
  }
}
fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}
fragment ProductFragment on ProductType {
  id
  name
  slug
  description
  price
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  usePlaceholderImage
  vendor {
    ...VendorFragment
  }
  child {
    __typename
    ... on ItemType {
      id
    }
    ... on ServiceType {
      id
      numberOfSessions
    }
  }
}
fragment ContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  vendor {
    name
    smallImage(resize: {fill: {width: 64, height: 64}}) {
      alt
      height
      id
      url
      width
    }
  }
  child {
    __typename
    ... on BlogType {
      id
      slug
    }
    ... on GuideType {
      id
      slug
    }
  }
}`) as unknown as TypedDocumentString<DashboardQuery, DashboardQueryVariables>
export const FaqQueryDocument = new TypedDocumentString(`
    query FaqQuery {
  faqs {
    id
    question
    answer
  }
}
    `) as unknown as TypedDocumentString<FaqQueryQuery, FaqQueryQueryVariables>
export const WebinarsDocument = new TypedDocumentString(`
    query Webinars($pagination: OffsetPaginationInput) {
  activeWebinars(pagination: $pagination) {
    items {
      id
      name
      registered
      description
      startAt
      endAt
      externalLink
      image(resize: {fill: {width: 400, height: 300}}) {
        url
      }
      vendor {
        name
        smallImage(resize: {fill: {width: 64, height: 64}}) {
          url
        }
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<WebinarsQuery, WebinarsQueryVariables>
export const AdminBookingApprovalDocument = new TypedDocumentString(`
    mutation AdminBookingApproval($input: ApproveBookingInput!) {
  adminBookingApproval(input: $input) {
    ... on BookingType {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<
  AdminBookingApprovalMutation,
  AdminBookingApprovalMutationVariables
>
export const UpdateBillingStatusDocument = new TypedDocumentString(`
    mutation UpdateBillingStatus($input: UpdateBillingStatusInput = {companyBillingStatus: PENDING}) {
  updateBillingStatus(input: $input) {
    ... on BookingType {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<
  UpdateBillingStatusMutation,
  UpdateBillingStatusMutationVariables
>
export const VendorBookingApprovalDocument = new TypedDocumentString(`
    mutation VendorBookingApproval($input: ApproveBookingInput!) {
  vendorBookingApproval(input: $input) {
    ... on BookingType {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<
  VendorBookingApprovalMutation,
  VendorBookingApprovalMutationVariables
>
export const SuggestBookingDateDocument = new TypedDocumentString(`
    mutation SuggestBookingDate($input: SuggestDateInput!) {
  suggestBookingDate(input: $input) {
    ... on BookingType {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<
  SuggestBookingDateMutation,
  SuggestBookingDateMutationVariables
>
export const VendorStatisticsDocument = new TypedDocumentString(`
    query VendorStatistics {
  vendorStatistics {
    bookedServices
    receiveBudget
  }
}
    `) as unknown as TypedDocumentString<VendorStatisticsQuery, VendorStatisticsQueryVariables>
export const BookingsDocument = new TypedDocumentString(`
    query Bookings($pagination: OffsetPaginationInput, $search: String, $filters: BookingFilter) {
  bookings(pagination: $pagination, search: $search, filters: $filters) {
    items {
      id
      status
      date
      price
      createdAt
      vendorBillingStatus
      companyBillingStatus
      employee {
        id
        user {
          firstName
          lastName
          email
          phoneNumber
        }
        company {
          name
        }
      }
      employeeComment
      product {
        name
        slug
        price
        child {
          __typename
          ... on ServiceType {
            numberOfSessions
          }
          ... on ItemType {
            id
          }
        }
        vendor {
          name
          slug
          smallImage(resize: {fill: {width: 64, height: 64}}) {
            url
          }
        }
      }
      actions {
        date
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<BookingsQuery, BookingsQueryVariables>
export const SharedUserInfoListDocument = new TypedDocumentString(`
    query SharedUserInfoList($pagination: OffsetPaginationInput, $search: String) {
  sharedUserInfoList(pagination: $pagination, search: $search) {
    items {
      slug
      user {
        id
        firstName
        lastName
        email
        companyMember {
          company {
            name
          }
        }
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<SharedUserInfoListQuery, SharedUserInfoListQueryVariables>
export const FavoriteProductsDocument = new TypedDocumentString(`
    query FavoriteProducts($pagination: OffsetPaginationInput = {}) {
  favoriteProducts(pagination: $pagination) {
    totalItemsCount
    items {
      id
      name
      slug
      description
      usePlaceholderImage
      price
      priceAlterText
      template
      image(resize: {max: {width: 1000, height: 1000}}) {
        url
      }
      images {
        image(resize: {max: {width: 1000, height: 1000}}) {
          url
        }
      }
      vendor {
        name
        smallImage(resize: {fill: {width: 64, height: 64}}) {
          url
        }
      }
      child {
        __typename
        ... on ItemType {
          id
        }
        ... on ServiceType {
          id
          numberOfSessions
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<FavoriteProductsQuery, FavoriteProductsQueryVariables>
export const FavoriteVendorsDocument = new TypedDocumentString(`
    query FavoriteVendors($pagination: OffsetPaginationInput = {}) {
  favoriteVendors(pagination: $pagination) {
    totalItemsCount
    items {
      name
      slug
      id
      description
      image(resize: {fill: {width: 480, height: 480}}) {
        url
      }
    }
  }
}
    `) as unknown as TypedDocumentString<FavoriteVendorsQuery, FavoriteVendorsQueryVariables>
export const FavoriteContentPagesDocument = new TypedDocumentString(`
    query FavoriteContentPages($pagination: OffsetPaginationInput = {}) {
  favoriteContentPages(pagination: $pagination) {
    totalItemsCount
    items {
      id
      title
      slug
      intro
      mainImage(resize: {fill: {width: 480, height: 480}}) {
        id
        url
      }
      vendor {
        name
        smallImage(resize: {fill: {width: 64, height: 64}}) {
          alt
          height
          id
          url
          width
        }
      }
      child {
        __typename
        ... on BlogType {
          id
        }
        ... on GuideType {
          id
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<
  FavoriteContentPagesQuery,
  FavoriteContentPagesQueryVariables
>
export const GuideDocument = new TypedDocumentString(`
    query Guide($slug: String!) {
  guide(slug: $slug) {
    id
    title
    slug
    intro
    body
    author
    chapters {
      header
      body
    }
    mainImage(resize: {fill: {width: 480, height: 480}}) {
      id
      url
    }
    vendor {
      id
      name
      slug
      smallImage(resize: {fill: {width: 64, height: 64}}) {
        alt
        height
        id
        url
        width
      }
    }
    images(resize: {max: {width: 800, height: 800}}) {
      baseImageId
      url
    }
    footerIcon {
      url
    }
    footerHeader
    footerText
    themes {
      id
      name
      icon {
        url
      }
    }
    tags {
      id
      name
    }
  }
}
    `) as unknown as TypedDocumentString<GuideQuery, GuideQueryVariables>
export const GuidesDocument = new TypedDocumentString(`
    query Guides($pagination: OffsetPaginationInput) {
  guides(pagination: $pagination) {
    items {
      id
      title
      slug
      intro
      mainImage(resize: {fill: {width: 1280, height: 600}}) {
        url
      }
      vendor {
        name
        smallImage(resize: {fill: {width: 64, height: 64}}) {
          url
        }
      }
      images(resize: {max: {width: 800, height: 800}}) {
        url
      }
      child {
        __typename
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<GuidesQuery, GuidesQueryVariables>
export const RequestEmailLoginCodeDocument = new TypedDocumentString(`
    mutation requestEmailLoginCode($email: Email!) {
  requestEmailLoginCode(input: {email: $email})
}
    `) as unknown as TypedDocumentString<
  RequestEmailLoginCodeMutation,
  RequestEmailLoginCodeMutationVariables
>
export const RequestTextLoginCodeDocument = new TypedDocumentString(`
    mutation requestTextLoginCode($emailCode: String!, $changePhoneNumber: PhoneNumber) {
  requestTextLoginCode(
    input: {emailCode: $emailCode, changePhoneNumber: $changePhoneNumber}
  )
}
    `) as unknown as TypedDocumentString<
  RequestTextLoginCodeMutation,
  RequestTextLoginCodeMutationVariables
>
export const LoginDocument = new TypedDocumentString(`
    mutation login($textCode: String!, $emailCode: String!) {
  login(input: {textCode: $textCode, emailCode: $emailCode}) {
    id
    email
    firstName
    lastName
    phoneNumber
    familyStatus
    gender
    dateOfBirth
    language
    companyMember {
      company {
        name
        slug
        budgetPerEmployee
      }
      isOwner
      isAdmin
      isEmployee
      budget
      totalBudget
      workEnergy
      privateEnergy
    }
    vendorMember {
      vendor {
        name
        slug
        smallImage(resize: {fill: {width: 80, height: 80}}) {
          url
        }
      }
    }
    userThemes {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<LoginMutation, LoginMutationVariables>
export const LogoutDocument = new TypedDocumentString(`
    mutation logout {
  logout
}
    `) as unknown as TypedDocumentString<LogoutMutation, LogoutMutationVariables>
export const AcceptTermsDocument = new TypedDocumentString(`
    mutation acceptTerms($input: AcceptTermsInput!) {
  acceptTerms(input: $input) {
    createdAt
    version
    content
    accepted
  }
}
    `) as unknown as TypedDocumentString<AcceptTermsMutation, AcceptTermsMutationVariables>
export const FillRegisterInfoDocument = new TypedDocumentString(`
    mutation FillRegisterInfo($code: String!, $input: UserInput!) {
  fillInRegisterInfo(code: $code, input: $input) {
    ... on UserType {
      email
      phoneNumber
    }
  }
}
    `) as unknown as TypedDocumentString<
  FillRegisterInfoMutation,
  FillRegisterInfoMutationVariables
>
export const EmailLoginInfoDocument = new TypedDocumentString(`
    query emailLoginInfo($emailCode: String!) {
  emailLoginInfo(input: {emailCode: $emailCode}) {
    user {
      firstName
      lastName
      email
      phoneNumber
    }
    organization {
      ... on CompanyType {
        name
      }
      ... on VendorType {
        name
      }
    }
  }
}
    `) as unknown as TypedDocumentString<EmailLoginInfoQuery, EmailLoginInfoQueryVariables>
export const MeDocument = new TypedDocumentString(`
    query me {
  me {
    id
    email
    firstName
    lastName
    phoneNumber
    familyStatus
    gender
    dateOfBirth
    language
    isSuperuser
    isStaff
    isCustomerSupport
    lastLogin
    companyMember {
      company {
        logo(resize: {fill: {width: 80, height: 80}}) {
          url
        }
        name
        slug
        budgetPerEmployee
        maxBudgetPerEmployee
        companyBudget
        accountType
      }
      isOwner
      isAdmin
      isEmployee
      budget
      totalBudget
      workEnergy
      privateEnergy
    }
    vendorMember {
      vendor {
        name
        slug
        smallImage(resize: {fill: {width: 80, height: 80}}) {
          url
        }
      }
    }
    userThemes {
      id
      isFavorite
      theme {
        name
        slug
        icon(resize: {fill: {width: 32, height: 32}}) {
          url
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<MeQuery, MeQueryVariables>
export const TermsQueryDocument = new TypedDocumentString(`
    query TermsQuery {
  terms {
    accepted
    content
    createdAt
    version
  }
}
    `) as unknown as TypedDocumentString<TermsQueryQuery, TermsQueryQueryVariables>
export const SendMessageDocument = new TypedDocumentString(`
    mutation SendMessage($input: SendMessageInput!) {
  sendMessage(input: $input) {
    id
  }
}
    `) as unknown as TypedDocumentString<SendMessageMutation, SendMessageMutationVariables>
export const ReadChatDocument = new TypedDocumentString(`
    mutation ReadChat($chatId: ID!) {
  readChat(input: {chatId: $chatId}) {
    id
  }
}
    `) as unknown as TypedDocumentString<ReadChatMutation, ReadChatMutationVariables>
export const CreateChatDocument = new TypedDocumentString(`
    mutation CreateChat($input: CreateChatInput!) {
  createChat(input: $input) {
    id
    participant2 {
      name
    }
    participant1 {
      isCurrentUser
    }
  }
}
    `) as unknown as TypedDocumentString<CreateChatMutation, CreateChatMutationVariables>
export const BookingDateApprovalDocument = new TypedDocumentString(`
    mutation BookingDateApproval($id: Int!) {
  bookingDateApproval(input: {id: $id, approved: true}) {
    ... on BookingType {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<
  BookingDateApprovalMutation,
  BookingDateApprovalMutationVariables
>
export const SendEmailForMessageByAdminDocument = new TypedDocumentString(`
    mutation SendEmailForMessageByAdmin($id: ID!) {
  sendEmailForMessageByAdmin(id: $id)
}
    `) as unknown as TypedDocumentString<
  SendEmailForMessageByAdminMutation,
  SendEmailForMessageByAdminMutationVariables
>
export const SendEmailForMessageDocument = new TypedDocumentString(`
    mutation SendEmailForMessage($id: ID!) {
  sendEmailForMessage(id: $id)
}
    `) as unknown as TypedDocumentString<
  SendEmailForMessageMutation,
  SendEmailForMessageMutationVariables
>
export const UpdateMessageDocument = new TypedDocumentString(`
    mutation UpdateMessage($id: ID!, $input: UpdateMessageInput!) {
  updateMessage(id: $id, input: $input) {
    id
  }
}
    `) as unknown as TypedDocumentString<UpdateMessageMutation, UpdateMessageMutationVariables>
export const UpdateChatDocument = new TypedDocumentString(`
    mutation UpdateChat($id: ID!, $input: UpdateChatInput!) {
  updateChat(id: $id, input: $input) {
    id
  }
}
    `) as unknown as TypedDocumentString<UpdateChatMutation, UpdateChatMutationVariables>
export const ChatContactsDocument = new TypedDocumentString(`
    query ChatContacts($pagination: OffsetPaginationInput, $filters: ChatFilter) {
  chats(pagination: $pagination, filters: $filters) {
    items {
      id
      participant1 {
        id
        name
        isCurrentUser
      }
      participant2 {
        id
        name
      }
      hasUnreadMessage
      messages(pagination: {limit: 1}) {
        items {
          content
          readAt
          createdAt
          type
        }
      }
      flagged
      archived
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<ChatContactsQuery, ChatContactsQueryVariables>
export const MessagesDocument = new TypedDocumentString(`
    query Messages($chatId: ID!, $pagination: OffsetPaginationInput, $filters: MessageFilter, $search: String) {
  chat(pk: $chatId) {
    messages(pagination: $pagination, filters: $filters, search: $search) {
      items {
        id
        direction
        content
        createdAt
        type
        sentEmailAt
        readAt
        archived
        markedUnread
        flagged
        senderEmail
      }
      totalItemsCount
    }
  }
}
    `) as unknown as TypedDocumentString<MessagesQuery, MessagesQueryVariables>
export const HasUnreadChatDocument = new TypedDocumentString(`
    query HasUnreadChat {
  hasUnreadChat {
    normal
    fromCustomerSupport
  }
}
    `) as unknown as TypedDocumentString<HasUnreadChatQuery, HasUnreadChatQueryVariables>
export const BookingDocument = new TypedDocumentString(`
    query Booking($id: ID!) {
  booking(pk: $id) {
    status
  }
}
    `) as unknown as TypedDocumentString<BookingQuery, BookingQueryVariables>
export const ChatTemplateDocument = new TypedDocumentString(`
    query ChatTemplate {
  chatTemplates {
    content
    id
  }
}
    `) as unknown as TypedDocumentString<ChatTemplateQuery, ChatTemplateQueryVariables>
export const ServiceDocument = new TypedDocumentString(`
    query service($slug: String!) {
  service(slug: $slug) {
    id
    name
    slug
    description
    richDescription
    vendor {
      ...VendorFragment
    }
    template
    image(resize: {max: {width: 1000, height: 1000}}) {
      url
    }
    images {
      image(resize: {max: {width: 1000, height: 1000}}) {
        url
      }
    }
    descriptionImages(resize: {max: {width: 800, height: 800}}) {
      baseImageId
      url
    }
    numberOfSessions
    locationGroup {
      name
      locations {
        id
        name
      }
    }
    price
    template
    usePlaceholderImage
    priceAlterText
    languages {
      code
    }
    themes {
      id
    }
    tags {
      id
    }
    __typename
  }
}
    fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}`) as unknown as TypedDocumentString<ServiceQuery, ServiceQueryVariables>
export const PdpDataDocument = new TypedDocumentString(`
    query pdpData($slug: String!) {
  productDetailPage(slug: $slug) {
    name
    slug
    score
    icon {
      url
    }
    descriptionHeader
    description
    tags {
      id
      name
      slug
    }
    highlights {
      highlightedItem {
        ... on ProductType {
          id
          __typename
          child {
            ... on ItemType {
              id
              __typename
            }
            ... on ServiceType {
              id
              __typename
            }
          }
        }
        ... on VendorType {
          id
          __typename
        }
        ... on ContentPageType {
          id
          __typename
          child {
            ... on BlogType {
              id
              __typename
            }
            ... on GuideType {
              id
              __typename
            }
          }
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<PdpDataQuery, PdpDataQueryVariables>
export const ProductsDocument = new TypedDocumentString(`
    query products($slug: String!, $filter: ProductFilter, $pagination: OffsetPaginationInput) {
  productDetailPage(slug: $slug) {
    products(filters: $filter, pagination: $pagination) {
      totalItemsCount
      items {
        id
        name
        slug
        description
        price
        priceAlterText
        template
        usePlaceholderImage
        image(resize: {max: {width: 1000, height: 1000}}) {
          url
        }
        images {
          image(resize: {max: {width: 1000, height: 1000}}) {
            url
          }
        }
        vendor {
          name
          smallImage(resize: {fill: {width: 64, height: 64}}) {
            url
          }
        }
        child {
          __typename
          ... on ItemType {
            id
          }
          ... on ServiceType {
            id
            numberOfSessions
          }
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<ProductsQuery, ProductsQueryVariables>
export const VendorsDocument = new TypedDocumentString(`
    query vendors($slug: String!, $pagination: OffsetPaginationInput) {
  productDetailPage(slug: $slug) {
    vendors(pagination: $pagination) {
      totalItemsCount
      items {
        name
        slug
        id
        description
        image(resize: {fill: {width: 480, height: 480}}) {
          url
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<VendorsQuery, VendorsQueryVariables>
export const ContentPagesDocument = new TypedDocumentString(`
    query contentPages($slug: String!, $pagination: OffsetPaginationInput) {
  productDetailPage(slug: $slug) {
    contentPages(pagination: $pagination) {
      totalItemsCount
      items {
        ...ContentPageFragment
      }
    }
  }
}
    fragment ContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  vendor {
    name
    smallImage(resize: {fill: {width: 64, height: 64}}) {
      alt
      height
      id
      url
      width
    }
  }
  child {
    __typename
    ... on BlogType {
      id
      slug
    }
    ... on GuideType {
      id
      slug
    }
  }
}`) as unknown as TypedDocumentString<ContentPagesQuery, ContentPagesQueryVariables>
export const TagsDocument = new TypedDocumentString(`
    query tags($slug: String!) {
  productDetailPage(slug: $slug) {
    productTags {
      id
      name
    }
  }
}
    `) as unknown as TypedDocumentString<TagsQuery, TagsQueryVariables>
export const VendorDocument = new TypedDocumentString(`
    query vendor($slug: String!) {
  vendor(slug: $slug) {
    id
    name
    description
    description2
    description3
    city
    locationGroup {
      name
    }
    website
    languages {
      code
    }
    studies {
      name
    }
    image(resize: {fill: {width: 480, height: 480}}) {
      url
    }
    smallImage(resize: {fill: {width: 64, height: 64}}) {
      url
    }
    tags {
      id
      slug
      name
    }
    themes {
      id
      slug
      name
    }
  }
}
    `) as unknown as TypedDocumentString<VendorQuery, VendorQueryVariables>
export const VendorProductsDocument = new TypedDocumentString(`
    query VendorProducts($vendorId: ID, $pagination: OffsetPaginationInput) {
  products(
    excludeWebinar: true
    filters: {vendor: {id: $vendorId}}
    pagination: $pagination
  ) {
    items {
      id
      name
      slug
      description
      price
      template
      priceAlterText
      usePlaceholderImage
      image(resize: {fill: {width: 480, height: 480}}) {
        url
      }
      images {
        image(resize: {max: {width: 1000, height: 1000}}) {
          url
        }
      }
      child {
        __typename
        ... on ItemType {
          id
        }
        ... on ServiceType {
          id
          numberOfSessions
        }
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<VendorProductsQuery, VendorProductsQueryVariables>
export const ProductItemDocument = new TypedDocumentString(`
    query ProductItem($slug: String!) {
  product(slug: $slug) {
    id
    name
    slug
    description
    richDescription
    vendor {
      ...VendorFragment
    }
    template
    image(resize: {max: {width: 1000, height: 1000}}) {
      url
    }
    images {
      image(resize: {max: {width: 1000, height: 1000}}) {
        url
      }
    }
    descriptionImages(resize: {max: {width: 800, height: 800}}) {
      baseImageId
      url
    }
    price
    priceAlterText
    usePlaceholderImage
    template
    themes {
      id
    }
    tags {
      id
    }
  }
}
    fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}`) as unknown as TypedDocumentString<ProductItemQuery, ProductItemQueryVariables>
export const UpdateInfoMutationDocument = new TypedDocumentString(`
    mutation UpdateInfoMutation($input: UserInput!) {
  updateProfile(input: $input) {
    ... on UserType {
      email
      phoneNumber
    }
  }
}
    `) as unknown as TypedDocumentString<
  UpdateInfoMutationMutation,
  UpdateInfoMutationMutationVariables
>
export const UpdateFavoriteThemeDocument = new TypedDocumentString(`
    mutation UpdateFavoriteTheme($input: UpdateThemeInput!) {
  updateFavoriteTheme(input: $input)
}
    `) as unknown as TypedDocumentString<
  UpdateFavoriteThemeMutation,
  UpdateFavoriteThemeMutationVariables
>
export const UserThemesDocument = new TypedDocumentString(`
    query UserThemes {
  me {
    userThemes {
      ...ScoredThemeFragment
    }
    nonThemeAnswers {
      text
      answer
    }
  }
}
    fragment ScoredThemeFragment on UserThemeType {
  id
  theme {
    id
    name
    slug
    order
    description
    color
    icon {
      url
    }
  }
  isFavorite
  score
  scanType
  answers {
    answer
    text
  }
}`) as unknown as TypedDocumentString<UserThemesQuery, UserThemesQueryVariables>
export const TimeLineDocument = new TypedDocumentString(`
    query TimeLine($pagination: OffsetPaginationInput, $lte: Date) {
  timeline(pagination: $pagination, filters: {date: {lte: $lte}}) {
    totalItemsCount
    items {
      id
      date
      image(resize: {fill: {width: 100, height: 100}}) {
        url
      }
      itemType
      title
    }
  }
}
    `) as unknown as TypedDocumentString<TimeLineQuery, TimeLineQueryVariables>
export const StartScanDocument = new TypedDocumentString(`
    mutation StartScan($input: StartScanInput!) {
  startScan(input: $input)
}
    `) as unknown as TypedDocumentString<StartScanMutation, StartScanMutationVariables>
export const FinishScanDocument = new TypedDocumentString(`
    mutation FinishScan($input: FinishScanInput!) {
  finishScan(input: $input) {
    id
    needSupport
  }
}
    `) as unknown as TypedDocumentString<FinishScanMutation, FinishScanMutationVariables>
export const AnswerBinaryQuestionDocument = new TypedDocumentString(`
    mutation AnswerBinaryQuestion($input: BinaryAnswerInput!) {
  answerBinaryQuestion(input: $input)
}
    `) as unknown as TypedDocumentString<
  AnswerBinaryQuestionMutation,
  AnswerBinaryQuestionMutationVariables
>
export const AnswerMultipleChoiceQuestionDocument = new TypedDocumentString(`
    mutation AnswerMultipleChoiceQuestion($input: MultipleChoiceAnswerInput!) {
  answerMultipleChoiceQuestion(input: $input)
}
    `) as unknown as TypedDocumentString<
  AnswerMultipleChoiceQuestionMutation,
  AnswerMultipleChoiceQuestionMutationVariables
>
export const AnswerScoreQuestionDocument = new TypedDocumentString(`
    mutation AnswerScoreQuestion($input: ScoreAnswerInput!) {
  answerScoreQuestion(input: $input)
}
    `) as unknown as TypedDocumentString<
  AnswerScoreQuestionMutation,
  AnswerScoreQuestionMutationVariables
>
export const AnswerSliderQuestionDocument = new TypedDocumentString(`
    mutation AnswerSliderQuestion($input: SliderAnswerInput!) {
  answerSliderQuestion(input: $input)
}
    `) as unknown as TypedDocumentString<
  AnswerSliderQuestionMutation,
  AnswerSliderQuestionMutationVariables
>
export const AnswerTextQuestionDocument = new TypedDocumentString(`
    mutation AnswerTextQuestion($input: TextAnswerInput!) {
  answerTextQuestion(input: $input)
}
    `) as unknown as TypedDocumentString<
  AnswerTextQuestionMutation,
  AnswerTextQuestionMutationVariables
>
export const SelfScanDocument = new TypedDocumentString(`
    query SelfScan {
  selfScan {
    id
    introHeader
    introText
    outroHeader
    outroText
    questions(pagination: {limit: 100}) {
      items {
        id
        description
        title
        type
        image(resize: {fill: {width: 600, height: 800}}) {
          url
        }
        child {
          ... on ScoreQuestionType {
            id
            maxScore
          }
          ... on MultipleChoiceQuestionType {
            id
            choices {
              weight
              text
            }
          }
          ... on BinaryQuestionType {
            id
          }
          ... on SliderQuestionType {
            id
            maxScore
          }
          ... on TextQuestionType {
            id
          }
        }
      }
      totalItemsCount
    }
  }
}
    `) as unknown as TypedDocumentString<SelfScanQuery, SelfScanQueryVariables>
export const CheckInDocument = new TypedDocumentString(`
    query CheckIn {
  checkIn {
    id
    introHeader
    introText
    outroHeader
    outroText
    questions(pagination: {limit: 100}) {
      items {
        id
        description
        title
        type
        image(resize: {fill: {width: 600, height: 800}}) {
          url
        }
        child {
          ... on ScoreQuestionType {
            id
            maxScore
          }
          ... on MultipleChoiceQuestionType {
            id
            choices {
              weight
              text
            }
          }
          ... on BinaryQuestionType {
            id
          }
          ... on SliderQuestionType {
            id
            maxScore
          }
          ... on TextQuestionType {
            id
          }
        }
      }
      totalItemsCount
    }
  }
}
    `) as unknown as TypedDocumentString<CheckInQuery, CheckInQueryVariables>
export const SearchQueryDocument = new TypedDocumentString(`
    query SearchQuery($pagination: OffsetPaginationInput, $search: String!, $filters: SearchItemFilter = {}, $ignoreMixContent: Boolean = false, $prioritizedOrder: [String!], $isTeamOffer: Boolean = false, $ignoreMixVendor: Boolean = false, $ids: [String!]) {
  search(
    search: $search
    pagination: $pagination
    filters: $filters
    ignoreMixContent: $ignoreMixContent
    prioritizedOrder: $prioritizedOrder
    isTeamOffer: $isTeamOffer
    ignoreMixVendor: $ignoreMixVendor
    ids: $ids
  ) {
    items {
      id
      text
      textEn
      category
      image {
        url
      }
      isBook
      itemId
      numberOfSessions
      order
      price
      priceAlterText
      usePlaceholderImage
      vendor {
        name
        smallImage {
          url
        }
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<SearchQueryQuery, SearchQueryQueryVariables>
export const MiniSearchQueryDocument = new TypedDocumentString(`
    query MiniSearchQuery($search: String!) {
  miniSearch(search: $search) {
    blogs {
      id
      text
      textEn
      category
      image {
        url
      }
      isBook
      itemId
      numberOfSessions
      order
      price
      priceAlterText
      usePlaceholderImage
      vendor {
        name
        smallImage {
          url
        }
      }
    }
    products {
      id
      text
      textEn
      category
      image {
        url
      }
      isBook
      itemId
      numberOfSessions
      order
      price
      priceAlterText
      usePlaceholderImage
      vendor {
        name
        smallImage {
          url
        }
      }
    }
    experts {
      id
      text
      textEn
      category
      image {
        url
      }
      isBook
      itemId
      numberOfSessions
      order
      price
      priceAlterText
      usePlaceholderImage
      vendor {
        name
        smallImage {
          url
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<MiniSearchQueryQuery, MiniSearchQueryQueryVariables>
export const TagQueryDocument = new TypedDocumentString(`
    query TagQuery {
  tags {
    name
    slug
    id
  }
}
    `) as unknown as TypedDocumentString<TagQueryQuery, TagQueryQueryVariables>
export const SearchHistoryQueryDocument = new TypedDocumentString(`
    query SearchHistoryQuery($search: String!) {
  searchHistory(search: $search) {
    id
    search
    updatedAt
  }
}
    `) as unknown as TypedDocumentString<SearchHistoryQueryQuery, SearchHistoryQueryQueryVariables>
export const ApproveRequestDocument = new TypedDocumentString(`
    mutation ApproveRequest($slug: String!) {
  shareInfoApproval(input: {slug: $slug, status: APPROVED})
}
    `) as unknown as TypedDocumentString<ApproveRequestMutation, ApproveRequestMutationVariables>
export const RejectRequestDocument = new TypedDocumentString(`
    mutation RejectRequest($slug: String!) {
  shareInfoApproval(input: {slug: $slug, status: REFUSED})
}
    `) as unknown as TypedDocumentString<RejectRequestMutation, RejectRequestMutationVariables>
export const ShareUserInfoDocument = new TypedDocumentString(`
    query ShareUserInfo($slug: String!) {
  sharedUserInfo(slug: $slug) {
    message
    status
    vendors {
      name
    }
    user {
      firstName
      lastName
      email
      companyMember {
        company {
          name
        }
        workEnergy
        privateEnergy
      }
      userThemes {
        ...ScoredThemeFragment
      }
      nonThemeAnswers {
        text
        answer
      }
    }
  }
}
    fragment ScoredThemeFragment on UserThemeType {
  id
  theme {
    id
    name
    slug
    order
    description
    color
    icon {
      url
    }
  }
  isFavorite
  score
  scanType
  answers {
    answer
    text
  }
}`) as unknown as TypedDocumentString<ShareUserInfoQuery, ShareUserInfoQueryVariables>
export const TagPageDocument = new TypedDocumentString(`
    query TagPage($slug: String!) {
  tagPage(slug: $slug) {
    tag {
      name
      slug
    }
    icon {
      url
    }
    descriptionHeader
    description
    highlights {
      highlightedItem {
        __typename
        ...HighLightedContentPageFragment
        ...VendorFragment
        ...ProductFragment
      }
    }
  }
}
    fragment HighLightedContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  child {
    __typename
    ... on BlogType {
      id
    }
    ... on GuideType {
      id
    }
  }
}
fragment VendorFragment on VendorType {
  id
  name
  slug
  description
  city
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  smallImage(resize: {fill: {width: 64, height: 64}}) {
    url
  }
  tags {
    name
  }
  themes {
    name
  }
  locationGroup {
    name
  }
}
fragment ProductFragment on ProductType {
  id
  name
  slug
  description
  price
  image(resize: {fill: {width: 480, height: 480}}) {
    url
  }
  usePlaceholderImage
  vendor {
    ...VendorFragment
  }
  child {
    __typename
    ... on ItemType {
      id
    }
    ... on ServiceType {
      id
      numberOfSessions
    }
  }
}`) as unknown as TypedDocumentString<TagPageQuery, TagPageQueryVariables>
export const TagProductsDocument = new TypedDocumentString(`
    query tagProducts($slug: String!, $filter: ProductFilter, $pagination: OffsetPaginationInput) {
  tagPage(slug: $slug) {
    products(filters: $filter, pagination: $pagination) {
      totalItemsCount
      items {
        id
        name
        slug
        description
        price
        priceAlterText
        template
        usePlaceholderImage
        image(resize: {fill: {width: 480, height: 480}}) {
          url
        }
        images {
          image(resize: {max: {width: 1000, height: 1000}}) {
            url
          }
        }
        vendor {
          name
          smallImage(resize: {fill: {width: 64, height: 64}}) {
            url
          }
        }
        child {
          __typename
          ... on ItemType {
            id
          }
          ... on ServiceType {
            id
            numberOfSessions
          }
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<TagProductsQuery, TagProductsQueryVariables>
export const TagVendorsDocument = new TypedDocumentString(`
    query tagVendors($slug: String!, $pagination: OffsetPaginationInput) {
  tagPage(slug: $slug) {
    vendors(pagination: $pagination) {
      totalItemsCount
      items {
        name
        slug
        id
        description
        image(resize: {fill: {width: 480, height: 480}}) {
          url
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<TagVendorsQuery, TagVendorsQueryVariables>
export const TagContentPagesDocument = new TypedDocumentString(`
    query tagContentPages($slug: String!, $pagination: OffsetPaginationInput) {
  tagPage(slug: $slug) {
    contentPages(pagination: $pagination) {
      totalItemsCount
      items {
        ...ContentPageFragment
      }
    }
  }
}
    fragment ContentPageFragment on ContentPageType {
  id
  title
  slug
  intro
  mainImage(resize: {fill: {width: 480, height: 480}}) {
    id
    url
  }
  vendor {
    name
    smallImage(resize: {fill: {width: 64, height: 64}}) {
      alt
      height
      id
      url
      width
    }
  }
  child {
    __typename
    ... on BlogType {
      id
      slug
    }
    ... on GuideType {
      id
      slug
    }
  }
}`) as unknown as TypedDocumentString<TagContentPagesQuery, TagContentPagesQueryVariables>
export const VendorsOverviewDocument = new TypedDocumentString(`
    query VendorsOverview($pagination: OffsetPaginationInput, $filters: VendorListFilter) {
  vendors(pagination: $pagination, filters: $filters) {
    items {
      id
      name
      slug
      description
      image {
        url
      }
    }
    totalItemsCount
  }
}
    `) as unknown as TypedDocumentString<VendorsOverviewQuery, VendorsOverviewQueryVariables>
export const AllTagsQueryDocument = new TypedDocumentString(`
    query AllTagsQuery {
  tags {
    name
    id
    slug
  }
}
    `) as unknown as TypedDocumentString<AllTagsQueryQuery, AllTagsQueryQueryVariables>
export const AllThemesQueryDocument = new TypedDocumentString(`
    query AllThemesQuery {
  themes {
    id
    name
    slug
  }
}
    `) as unknown as TypedDocumentString<AllThemesQueryQuery, AllThemesQueryQueryVariables>
